import { useField } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import Select, { ControlProps, Props, Styles } from "react-select";
import styles from "../Styles/exports.module.scss";

type OptionType = { label: string; value: string };

const customStyles: Partial<Styles> = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    paddingTop: "calc(1em)",
    border: "0",
    borderRadius: 0,
    height: "calc(1.5rem + 2em)",
    boxShadow: `inset 0 ${state.isFocused ? "-2px" : "-1px"} 0 ${
      !state.isFocused ? "#ddd" : styles.primary
    }`,
    transition: "box-shadow .2s",
    backgroundColor: "none",
  }),
  container: (provided, state) => ({
    ...provided,
    lineHeight: "1.5",
    fontSize: "1rem",
    margin: "0",
    border: "0",
    maxHeight: "calc(1.5rem + 2em)",
    boxSizing: "content-box",
  }),
  indicatorsContainer: (provided, state) => ({
    padding: "0",
    margin: "0",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: "0",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "darkgray",
    fontSize: "1rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    marginTop: "2px",
    opacity: "1",
    transition: "opacity .2s",
    zIndex: 9999999999,
  }),
  menuList: (provided, state: ControlProps<{}>) => ({
    ...provided,
    padding: "0",
    borderRadius: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    boxShadow: "none",
  }),
};

const FormSelect = (({ label, column, options, ...props }) => {
  const [field, meta, helpers] = useField(props as any);
  const groupProps = column
    ? { as: Col, className: column === true ? "" : `col-${column}` }
    : {};

  const value = (options as OptionType[])?.find?.(
    (option: any) => option.value === field.value
  );

  const onChange = (evt: any) => helpers.setValue(evt.value);
  const isOptionDisabled = (option: any) => !!option.disabled;

  return (
    <Form.Group controlId={props.name} {...groupProps}>
      <Select
        {...field}
        {...props}
        className={`select-custom ${
          !!(meta.touched && meta.error) ? "is-invalid" : ""
        }`}
        options={options}
        value={value as any}
        onChange={onChange}
        onBlur={() => helpers.setTouched(true)}
        styles={customStyles}
        isOptionDisabled={isOptionDisabled}
      />
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}) as React.FC<
  Partial<Props<{ label: string; value: string; disabled?: boolean }>> & {
    name: string;
    label?: string;
    column?: boolean | number;
  }
>;

export default FormSelect;
