import React from "react";
import { Button, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import { Plus, X } from "react-feather";
import { useField } from "formik";

const FormMulti = (({ name, min, max, label, column, onChange }) => {
  const [field, meta, helpers] = useField({ name });

  const value: string[] = field.value ?? [];

  const update = (idx: number, val: string) => {
    let newVal = value.slice();
    newVal[idx] = val;
    helpers.setValue(newVal);
    helpers.setTouched(true);
  };

  const add = () => {
    let newVal = value.slice();
    newVal.push("");
    helpers.setValue(newVal);
    helpers.setTouched(true);
  };
  const remove = (idx: number) => {
    let newVal = value.slice();
    newVal.splice(idx, 1);
    helpers.setValue(newVal);
    helpers.setTouched(true);
  };

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Container
        {...(column
          ? {
              as: Col,
              className: `col-${column} bg-secondary rounded p-3 my-1`,
            }
          : { className: "bg-secondary rounded p-3 my-1" })}
      >
        {value.map((val, i) => (
          <Form.Group key={i} as={Row} className="w-100">
            <InputGroup as={Col}>
              <Form.Control
                type="text"
                onChange={(evt) => update(i, evt.target.value)}
                onBlur={() => helpers.setTouched(true)}
                value={value[i]}
                className="px-0"
              />
              <InputGroup.Append>
                <Button
                  variant="link"
                  onClick={() => remove(i)}
                  className="p-0 multi-remove-button"
                >
                  <X />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        ))}
        {value.length < (max ?? Infinity) && (
          <Button onClick={add} variant="success" className="px-0 mx-0">
            <Plus />
          </Button>
        )}
        <Row className="w-100 mt-3">
          <Col className="text-danger" style={{ fontSize: ".8em" }}>
            {meta.touched &&
              ((meta.error! as any) instanceof Array
                ? ((meta.error! as unknown) as string[]).map((err: any) => (
                    <div>{err}</div>
                  ))
                : meta.error)}
          </Col>
        </Row>
      </Container>
    </>
  );
}) as React.FC<{
  name: string;
  min?: number;
  max?: number;
  value?: string[];
  label?: string;
  column?: boolean | number;
  onChange?: (value: string[]) => void;
}>;

export default FormMulti;
