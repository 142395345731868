import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NewHomeForm from "../../Components/Forms/NewHomeForm";
import { CreateHome } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import { UserContext } from "../../Sources/UserSource";
import {
  CreateHome as CreateHomeQuery,
  CreateHomeVariables,
} from "../../types/CreateHome";
import NewHomeSchema from "../../Validators/NewHome";

const NewHome = () => {
  const { user } = useContext(UserContext);
  const { addToast } = useContext(RouteContext);
  const { country, state, features } = (user! ?? {}).organization!;
  const [createHome] = useMutation<CreateHomeQuery, CreateHomeVariables>(
    CreateHome
  );
  const history = useHistory();

  const onSubmit = async ({
    buyer_location: { address_1, address_2, city, state, postal_code, country },
    min_baths,
    min_beds,
    min_price,
    max_price,
    first_name,
    last_name,
    phone_number,
    email,
    renting,
    type,
    features,
    quality,
    auto_complete,
    needs_sale,
    additional_notes,
    oncollaboption,
  }: any) => {
    const variables: CreateHomeVariables = {
      input: {
        organization: { connect: user?.organization?.id },
        address_1,
        address_2,
        city,
        state,
        postal_code,
        country,
        first_name,
        last_name,
        phone_number,
        email,
        renting,
        min_beds,
        min_baths,
        min_price,
        max_price,
        type: {
          connect: type ?? 0,
        },
        features: {
          create:
            Object.keys(features ?? []).map((id) => ({
              feature: { connect: id },
              value: features[id] ?? "NA",
            })) ?? "",
        },
        quality: {
          connect: quality ?? 0,
        },
        locations: {
          create:
            (auto_complete ?? []).map((loc: any) => ({
              location: loc.label,
            })) ?? "",
        },
        needs_sale,
        additional_notes,
        oncollaboption,
      },
    };
    const result = await createHome({ variables });

    if (result.data?.createHome) {
      addToast({
        header: (
          <>
            <strong>Success!</strong>
          </>
        ),
        body: "New Buyer successfully created!",
      });
      history.push("/homes");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>New Buyer</h1>
          <p>Fill in all of the fields below.</p>
        </Col>
      </Row>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone_number: "",
          email: "",
          buyer_location: {
            address: "",
            address2: "",
            city: "",
            state,
            postal: "",
            country,
          },
          renting: false,
          locations: [],
          needs_sale: true,
          max_price: 10000,
          min_price: 0,
          min_beds: 2,
          min_baths: 2,
          type: null,
          quality: null,
          features: features?.reduce(
            (acc, feature) => ({
              [feature.id]: null,
            }),
            {} as any
          ),
          additional_notes: "",
          oncollaboption: false,
        }}
        validationSchema={NewHomeSchema}
        onSubmit={onSubmit}
      >
        <NewHomeForm />
      </Formik>
    </Container>
  );
};

export default NewHome;
