import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext } from "react";
import { RouteContext } from "../../Routing/Routing";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import GeneralUser from "../../Components/Forms/GeneralUser";
import { GetUser, UpdateUser } from "../../Queries";
import { GetUser as GetUserQuery, GetUserVariables } from "../../types/GetUser";
import Loader from "../../Components/Loader";
import { IGeneralUserForm } from "../../Interfaces";
import { Role } from "../../types/globalTypes";
import {
  UpdateUser as UpdateUserData,
  UpdateUserVariables,
} from "../../types/UpdateUser";
import ManageUserSchema from "../../Validators/ManageUser";

const ManageUser = () => {
  const history = useHistory();
  let { uid: id } = useParams<{ uid: string }>();
  const { addToast } = useContext(RouteContext);
  const { data, loading } = useQuery<GetUserQuery, GetUserVariables>(GetUser, {
    variables: {
      id,
    },
  });

  let org_id = data?.user?.organization?.id ?? null;
  let usr_id = Number(id);
  const [updateUser] = useMutation<UpdateUserData, UpdateUserVariables>(
    UpdateUser
  );

  // TODO: Implement actual user mutation
  const onSubmit = async ({
    id,
    email,
    organization,
    first_name,
    last_name,
    phone_number,
    role,
    password,
  }: IGeneralUserForm) => {
    //Check if password is updating or not

    try {
      if (password) {
        const variables = {
          input: {
            id: usr_id,
            email,
            organization: { connect: org_id },
            first_name,
            last_name,
            phone_number,
            role,
            password,
          },
        };

        const { data } = await updateUser({ variables });
        //if sucess then show message
        if (data?.updateUser) {
          addToast({
            header: <strong>Success!</strong>,
            body: "User Details successfully updated!",
          });
          history.push(`/manage/organizations/${org_id}`);
        }
      } else {
        const variables = {
          input: {
            id: usr_id,
            email,
            organization: { connect: org_id },
            first_name,
            last_name,
            phone_number,
            role,
          },
        };
        const { data } = await updateUser({ variables });
        //if sucess then show message
        if (data?.updateUser) {
          addToast({
            header: <strong>Success!</strong>,
            body: "User Details successfully updated!",
          });
          history.push(`/manage/organizations/${org_id}`);
        }
      }
    } catch (err) {
      addToast({
        header: (
          <>
            <strong>Exception Occurred</strong>
          </>
        ),
        body: err.toString(),
      });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage User</h1>
        </Col>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            id: usr_id,
            first_name: data?.user?.first_name ?? "",
            last_name: data?.user?.last_name ?? "",
            organization: (data?.user?.organization?.id ?? null) as
              | number
              | null,
            role: data?.user?.role ?? Role.Client,
            email: data?.user?.email ?? "",
            phone_number: data?.user?.phone_number ?? "",
            password_confirm: "",
            password: "",
          }}
          onSubmit={onSubmit}
          validationSchema={ManageUserSchema}
        >
          <GeneralUser />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageUser;
