import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import DirectoryEntry from "../../Components/DirectoryEntry";
import { GetDirectoryInfo as GetDirectoryInfoQuery } from "../../types/GetDirectoryInfo";
import { GetDirectoryInfo } from "../../Queries";
import { useQuery } from "@apollo/client";

const Directory = () => {
  //call to get all directory categories and contacts
  const { data } = useQuery<GetDirectoryInfoQuery>(GetDirectoryInfo, {});

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Directory</h1>
        </Col>
      </Row>
      <div>
        <Accordion as={Col} className="pt-1 pb-1">
          {data?.self?.organization?.directoryCategories?.map((dir) => (
            <>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={`d${dir.id}`}
                  >
                    {dir?.name}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`d${dir.id}`}>
                  <Card.Body>
                    <Accordion>
                      {dir?.directories?.map((contacts) => (
                        <DirectoryEntry
                          id={Number(contacts?.id)}
                          firstName={contacts?.first_name}
                          lastName={contacts?.last_name}
                          phoneNumber={contacts?.phone_number}
                          email={contacts?.email}
                          location={{
                            address1: contacts?.address_1,
                            address2: contacts?.address_2 ?? "",
                            city: contacts?.city,
                            state: contacts?.state,
                            postal: contacts?.postal_code,
                            country: contacts?.country,
                          }}
                        />
                      ))}
                    </Accordion>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </>
          ))}
        </Accordion>
      </div>
    </Container>
  );
};

export default Directory;
