import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ManageDirectoryComp from "../../Components/Forms/ManageDirectoryForm";
import { CreateDirectoryCategory } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateDirectoryCategory as CreateDirectoryCategoryQuery,
  CreateDirectoryCategoryVariables,
} from "../../types/CreateDirectoryCategory";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";

const NewDirectoryCategory = () => {
  const [createDirectoryCategory] = useMutation<
    CreateDirectoryCategoryQuery,
    CreateDirectoryCategoryVariables
  >(CreateDirectoryCategory);

  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);

  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  const onSubmit = async (input: any) => {
    let { data } = await createDirectoryCategory({
      variables: {
        input: {
          name: input.name,
          organization: {
            connect: oid,
          },
        },
      },
    });

    if (data?.createDirectoryCategory) {
      //if sucess then show message
      addToast({
        header: <strong>Success!</strong>,
        body: "Directory successfully created!",
      });
      history.push(
        `/manage/directorycategories/${data?.createDirectoryCategory.id}`
      );
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>New Directory</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        validationSchema={ManageCompSchema}
      >
        <ManageDirectoryComp />
      </Formik>
    </Container>
  );
};

export default NewDirectoryCategory;
