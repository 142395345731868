import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import GeneralUser from "../../Components/Forms/GeneralUser";
import {
  CreateUser as CreateUserQuery,
  CreateUserVariables,
} from "../../types/CreateUser";
import { CreateUser } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import { IGeneralUserForm } from "../../Interfaces";
import { Role } from "../../types/globalTypes";
import CreateUserSchema from "../../Validators/CreateNewuser";

const NewUser = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  const [createUser] = useMutation<CreateUserQuery, CreateUserVariables>(
    CreateUser
  );

  useEffect(() => {
    if (oid == null) {
    }
  }, [oid, history]);

  const onSubmit = async ({
    first_name,
    last_name,
    organization,
    role,
    email,
    phone_number,
    password,
  }: IGeneralUserForm) => {
    const variables: CreateUserVariables = {
      input: {
        first_name,
        last_name,
        organization: {
          connect: organization != null ? organization.toString() : null,
        },
        phone_number,
        email,
        role,
        password,
      },
    };
    try {
      const { data } = await createUser({ variables });

      if (data?.createUser) {
        addToast({
          header: (
            <>
              <strong>Success!</strong>
            </>
          ),
          body: "User successfully created!",
        });
        history.push(`/manage/users/${data.createUser.id}`);
      }
    } catch (err) {
      addToast({
        header: (
          <>
            <strong>Exception Occurred</strong>
          </>
        ),
        body: err.toString(),
      });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>New User</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          role: Role.Client,
          email: "",
          phone_number: "",
          organization: oid ?? null,
          password: "",
          password_confirm: "",
        }}
        onSubmit={onSubmit}
        validationSchema={CreateUserSchema}
      >
        <GeneralUser />
      </Formik>
    </Container>
  );
};

export default NewUser;
