import { NetworkStatus, useQuery } from "@apollo/client";
import React, { useState } from "react";
import Axios from "../AxiosInstance";
import { ILoginCredentials } from "../Interfaces";
import { GetSelf } from "../Queries";
import { GetSelf as GetSelfResult, GetSelf_self } from "../types/GetSelf";

export const UserContext = React.createContext({
  loggedIn: false,
  user: undefined as GetSelf_self | undefined,
  loading: true,
  loginErrors: null as any,
  logIn: async ({ email, password, remember }: ILoginCredentials) => {},
  logOut: async () => {},
  reloadUser: () => {},
});

const UserSource = function ({ children }) {
  const [loading, setLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState(null);
  const {
    loading: queryLoading,
    refetch: reloadUser,
    networkStatus,
    data,
    client,
  } = useQuery<GetSelfResult>(GetSelf, {
    notifyOnNetworkStatusChange: true,
  });

  /** Log in a user */
  async function logIn({ email, password, remember }: ILoginCredentials) {
    try {
      // Send the login request
      await Axios.post("/login", {
        email,
        password,
        remember,
      });

      // Clear the query cache
      client
        .clearStore()
        // And reload the user information
        .then(() => reloadUser())
        .then(() => setLoading(false));
    } catch (e) {
      let errors = e.response.data?.errors;

      for (let key in errors) {
        errors[key] = errors[key].join(", ");
      }

      setLoginErrors(errors);
      throw e;
    }
  }

  async function logOut() {
    setLoading(true);
    // Send the logout request
    await Axios.post("/logout");
    // Clear the query cache
    client
      .resetStore()
      // And reload the user information
      .then(() => reloadUser())
      .then(() => setLoading(false));
  }

  return (
    <UserContext.Provider
      value={{
        loggedIn: data?.self != null,
        user: data?.self ?? undefined,
        logIn,
        logOut,
        reloadUser,
        loading:
          loading || queryLoading || networkStatus === NetworkStatus.refetch,
        loginErrors,
      }}
    >
      {children}
    </UserContext.Provider>
  );
} as React.FC<{}>;

export { UserSource };
