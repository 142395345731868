import { Form as FormikForm, useFormikContext } from "formik";
import React, { HTMLProps, useEffect } from "react";
import { Form as BSForm } from "react-bootstrap";
import FormField from "./FormField";
import FormMulti from "./FormMulti";
import FormSelect from "./FormSelect";

const Form = ((props) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (props.errors != null) {
      formik.setErrors(props.errors);
      for (let key in props.errors) {
        if (!formik.getFieldMeta(key).touched)
          formik.setFieldTouched(key, true);
      }
    }
  }, [props.errors, formik]);

  return <BSForm as={FormikForm as any} noValidate {...props} />;
}) as React.FC<HTMLProps<HTMLFormElement> & { errors?: any }> & {
  Field: typeof FormField;
  Select: typeof FormSelect;
  Row: typeof BSForm.Row;
  Multi: typeof FormMulti;
};

Form.Field = FormField;
Form.Select = FormSelect;
Form.Row = BSForm.Row;
Form.Multi = FormMulti;

export default Form;
