import * as yup from "yup";

const SettingsSchema = yup.object().shape({
  priceInterval: yup.number().required("Please enter interval value").min(0),
  maxNoteLength: yup
    .number()
    .required("Please enter maximum note length")
    .max(10000)
    .min(0),
  autoLogoutDelay: yup
    .number()
    .required("Please enter auto logout time")
    .max(10000)
    .min(0),
});

export default SettingsSchema;
