import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GetOrganizations } from "../../Queries";
import { GetOrganizations as GetOrganizationsQuery } from "../../types/GetOrganizations";
import { Role } from "../../types/globalTypes";
import Form from "../Form";
import Loader from "../Loader";

const GeneralUser = (() => {
  const { data, loading } = useQuery<GetOrganizationsQuery>(GetOrganizations);
  const { values, isValid, dirty, isSubmitting } = useFormikContext<any>();

  const orgs = useMemo(() => {
    return [
      {
        label: "(none)",
        value: null as any,
      },
    ].concat(
      data?.organizations?.data?.map((org) => ({
        label: org.name,
        value: org.id,
      })) ?? []
    );
  }, [data?.organizations]);

  return (
    <Form className="mt-4" autoComplete="off">
      <Loader loaded={!isSubmitting} />
      <Form.Row>
        <Form.Field
          type="text"
          name="first_name"
          placeholder="First Name"
          autoComplete="off"
          column
        />
        <Form.Field
          type="text"
          name="last_name"
          placeholder="Last Name"
          autoComplete="off"
          column
        />
      </Form.Row>
      <Form.Row>
        <Form.Select
          name="organization"
          placeholder="Organization"
          column
          options={orgs ?? []}
          isLoading={loading || orgs.length === 0}
        />
        <Form.Select
          name="role"
          placeholder="Role"
          column
          options={[
            {
              value: Role.Realtor,
              label: "Realtor",
              disabled: values.organization == null,
            },
            {
              value: Role.OrgAdmin,
              label: "Organization Administrator",
              disabled: values.organization == null,
            },
            {
              value: Role.SysOp,
              label: "Service Administrator",
            },
          ]}
        />
      </Form.Row>
      <Form.Row className="mt-3">
        <Form.Field
          type="email"
          name="email"
          placeholder="Contact Email"
          autoComplete="off"
          column
        />
        <Form.Field
          type="tel"
          name="phone_number"
          placeholder="Contact Phone"
          autoComplete="off"
          column
        />
      </Form.Row>
      <Form.Row className="mt-4">
        <Form.Field
          name="password"
          type="password"
          placeholder="New Password"
          column
          autoComplete="new-password"
        />
        <Form.Field
          name="password_confirm"
          type="password"
          placeholder="Confirm Password"
          column
          autoComplete="new-password"
        />
      </Form.Row>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid || !dirty}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}) as React.FC;

export default GeneralUser;
