import React from "react";
import { Card, Accordion, Button, Table } from "react-bootstrap";

const DirectoryEntry = (({
  id,
  firstName,
  lastName,
  phoneNumber,
  email,
  location,
}) => {
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={`u${id}`}>
          {lastName}, {firstName}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={`u${id}`}>
        <Card.Body>
          <Table>
            <tbody>
              <tr>
                <td>
                  <b>Phone Number</b>
                </td>
                <td>{phoneNumber}</td>
              </tr>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>
                  <b>Address</b>
                </td>
                <td>
                  {location.address1}
                  {location.address2 && (
                    <>
                      <br />
                      {location.address2}
                    </>
                  )}
                  <br />
                  {location.city}, {location.state}
                  <br />
                  {location.postal}
                  <br />
                  {location.country}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}) as React.FC<{
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  location: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postal: string;
    country: string;
  };
}>;

export default DirectoryEntry;
