import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ManageHomeTypeComp from "../../Components/Forms/ManageHomeTypeForm";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";
import { useMutation, useQuery } from "@apollo/client";
import { IHomeTypeForm } from "../../Interfaces";
import { RouteContext } from "../../Routing/Routing";
import Loader from "../../Components/Loader";
import { DeleteHomeType, GetOrganization, UpdateHomeType } from "../../Queries";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";
import {
  DeleteHomeType as DeleteHomeTypeData,
  DeleteHomeTypeVariables,
} from "../../types/DeleteHomeType";

import {
  UpdateHomeType as UpdateHomeTypeData,
  UpdateHomeTypeVariables,
} from "../../types/UpdateHomeType";

const ManageHomeType = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  let { htid } = useParams<{ htid: string }>();

  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  let type_id =
    data?.organization?.homeTypes.find(
      (hometype) => hometype.id === (htid as string)
    ) ?? null;

  //call to delete home type features
  const [deleteHomeType] = useMutation<
    DeleteHomeTypeData,
    DeleteHomeTypeVariables
  >(DeleteHomeType);

  const [updateHomeType] = useMutation<
    UpdateHomeTypeData,
    UpdateHomeTypeVariables
  >(UpdateHomeType);

  async function handleDelete() {
    const variables: DeleteHomeTypeVariables = {
      id: type_id?.id as string,
    };
    const { data } = await deleteHomeType({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Type successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  async function submitForm({ name, description }: IHomeTypeForm) {
    const variables = {
      input: {
        id: type_id?.id as string,
        name,
        description,
      },
    };

    const { data } = await updateHomeType({ variables });

    //if sucess then show message
    if (data?.updateHomeType) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Type successfully updated!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Edit Home Type</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!type_id?.id}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            organization: "",
            name: type_id?.name ?? "",
            description: "",
          }}
          onSubmit={submitForm}
          validationSchema={ManageCompSchema}
        >
          <ManageHomeTypeComp />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageHomeType;
