import { ApolloQueryResult } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { MinimalPaginatorInfo } from "../types/MinimalPaginatorInfo";
import { Pagination as BSPagination } from "react-bootstrap";

const Pagination = function ({ paginatorInfo, refetch }) {
  let entries: React.ReactNode[] = [];

  const [pageInfo, setPageInfo] = useState(paginatorInfo);

  useEffect(() => {
    if (paginatorInfo != null) {
      setPageInfo(paginatorInfo);
    }
  }, [paginatorInfo]);

  if (pageInfo) {
    for (let i = 1; i <= pageInfo?.lastPage; i++) {
      entries.push(
        <BSPagination.Item
          key={i}
          active={i === pageInfo?.currentPage}
          onClick={() => refetch({ page: i })}
        >
          {i}
        </BSPagination.Item>
      );
    }
  }

  return (
    <BSPagination>
      <BSPagination.Prev
        disabled={pageInfo?.currentPage === 1}
        key={-1}
        onClick={() => refetch({ page: (pageInfo?.currentPage ?? 2) - 1 })}
      ></BSPagination.Prev>
      {entries}
      <BSPagination.Next
        disabled={!pageInfo?.hasMorePages}
        onClick={() => refetch({ page: (pageInfo?.currentPage ?? 1) + 1 })}
      ></BSPagination.Next>
    </BSPagination>
  );
} as React.FC<{
  paginatorInfo?: MinimalPaginatorInfo;
  refetch: (
    variables?: Partial<{ first: number; page: number }>
  ) => Promise<ApolloQueryResult<any>>;
}>;

export default Pagination;
