import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ApolloSetup from "./ApolloSetup";
import App from "./App";
import Axios from "./AxiosInstance";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import Loader from "./Components/Loader";
import CriticalError from "./Views/CriticalError";

/** Initializes required parts of the application before finally starting the application. */
const Bootstrap = (() => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [criticalStage, setCriticalStage] = useState<string>();
  const [criticalError, setCriticalError] = useState<any>();

  useEffect(() => {
    Axios
      // Fetch the CSRF token
      .get("/sanctum/csrf-cookie")
      .then(() => {
        // Set up Apollo
        const client = ApolloSetup((stage, error, critical) => {
          if (critical) {
            setCriticalStage(stage);
            setCriticalError(error);
          }
        });
        setClient(client);
        setLoading(false);
      })
      .catch((err: any) => {
        // Show the CriticalError screen
        setCriticalError(err);
        setCriticalStage("CSRF setup");
        setLoading(false);
      });
  }, []);

  return (
    <Loader loaded={!loading}>
      {criticalError != null ? (
        <CriticalError err={criticalError} stage={criticalStage!} />
      ) : (
        <ErrorBoundary>
          <ApolloProvider client={client!}>
            <Router>
              <App />
            </Router>
          </ApolloProvider>
        </ErrorBoundary>
      )}
    </Loader>
  );
}) as React.FC<{}>;

export default Bootstrap;
