import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ManageFeatureComp from "../../Components/Forms/ManageFeatureForm";
import { IHomeFeatureForm } from "../../Interfaces";
import { CreateHomeFeatures } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateHomeFeatures as CreateHomeFeatureData,
  CreateHomeFeaturesVariables,
} from "../../types/CreateHomeFeatures";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";

const ManageFeature = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  //call to create home type features
  const [createHomeFeatures] = useMutation<
    CreateHomeFeatureData,
    CreateHomeFeaturesVariables
  >(CreateHomeFeatures);

  //submit call to send data
  async function submitForm({ name, description }: IHomeFeatureForm) {
    const variables = {
      input: {
        organization: { connect: oid },
        name,
        description,
      },
    };

    const { data } = await createHomeFeatures({ variables });

    //if sucess then show message
    if (data?.createFeature) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Feature successfully created!",
      });
      history.push(`/manage/features/${data?.createFeature.id}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Feature</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{ organization: "", name: "", description: "" }}
        onSubmit={submitForm}
        validationSchema={ManageCompSchema}
      >
        <ManageFeatureComp />
      </Formik>
    </Container>
  );
};

export default ManageFeature;
