import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trash } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import ManageHomeForm from "../../Components/Forms/ManageHomeForm";
import Loader from "../../Components/Loader";
import {
  DeleteHome,
  DeleteHomeLocation,
  GetHome,
  UpdateHome,
} from "../../Queries";
import { UserContext } from "../../Sources/UserSource";
import {
  DeleteHome as DeleteHomeQuery,
  DeleteHomeVariables,
} from "../../types/DeleteHome";
import {
  UpdateHome as UpdateHomeQuery,
  UpdateHomeVariables,
} from "../../types/UpdateHome";
import {
  DeleteHomeLocation as DeleteHomeLocationQuery,
  DeleteHomeLocationVariables,
} from "../../types/DeleteHomeLocation";
import { GetHome as GetHomeQuery, GetHomeVariables } from "../../types/GetHome";
import ManageHomeSchema from "../../Validators/ManageHome";
import { RouteContext } from "../../Routing/Routing";

const ManageHome = () => {
  const { user } = useContext(UserContext);
  const { addToast } = useContext(RouteContext);
  const { features } = (user! ?? {}).organization!;
  const history = useHistory();

  const { bid } = useParams<{ bid: string }>();
  const { data, loading } = useQuery<GetHomeQuery, GetHomeVariables>(GetHome, {
    variables: {
      id: bid,
    },
  });
  const [updateHome] = useMutation<UpdateHomeQuery, UpdateHomeVariables>(
    UpdateHome
  );
  const [deleteHome] = useMutation<DeleteHomeQuery, DeleteHomeVariables>(
    DeleteHome
  );
  const [deleteHomeLocation] = useMutation<
    DeleteHomeLocationQuery,
    DeleteHomeLocationVariables
  >(DeleteHomeLocation);
  const onSubmit = async ({
    buyer_location: { address_1, address_2, city, state, postal_code, country },
    min_baths,
    min_beds,
    min_price,
    max_price,
    first_name,
    last_name,
    phone_number,
    email,
    renting,
    type,
    features,
    quality,
    auto_complete,
    needs_sale,
    additional_notes,
    oncollaboption,
  }: any) => {
    const variables: UpdateHomeVariables = {
      input: {
        id: +bid,
        organization: { connect: user?.organization?.id },
        address_1,
        address_2,
        city,
        state,
        postal_code,
        country,
        first_name,
        last_name,
        phone_number,
        email,
        renting,
        min_beds,
        min_baths,
        min_price,
        max_price,
        type: {
          connect: type ?? 0,
        },
        features: {
          create: Object.keys(features).map((id) => ({
            feature: { connect: id },
            value: features[id] ?? "NA",
          })),
        },
        quality: {
          connect: quality ?? 0,
        },
        locations: {
          create: auto_complete.map((loc: any) => ({
            location: loc.label,
          })),
        },
        needs_sale,
        additional_notes,
        oncollaboption,
      },
    };
    updateHomeLocatios();

    const result = await updateHome({ variables });
    if (result) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Successfully Updated!",
      });
      history.push("/homes");
    }
  };

  const LocationIDs = (data?.home?.locations ?? []).map((loc) => loc?.id);
  async function updateHomeLocatios() {
    const variables: any = {
      id: LocationIDs?.map((loc) => loc),
    };

    const { data } = await deleteHomeLocation({ variables });
    // if sucess then show message
    if (data) {
    }
  }
  async function handleDelete() {
    const variables: DeleteHomeVariables = {
      id: bid,
    };
    const { data } = await deleteHome({ variables });
    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Successfully Deleted!",
      });
      history.push("/homes");
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col className="w-100">
          <h1>Manage Buyer</h1>
        </Col>
        <Col>
          <Button
            variant="danger"
            className="float-right"
            onClick={handleDelete}
          >
            <Trash /> Delete
          </Button>
        </Col>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            first_name: data?.home?.first_name ?? "",
            last_name: data?.home?.last_name ?? "",
            phone_number: data?.home?.phone_number ?? "",
            email: data?.home?.email ?? "",
            renting: data?.home?.renting,
            buyer_location: {
              address_1: data?.home?.address_1 ?? "",
              address_2: data?.home?.address_2 ?? "",
              city: data?.home?.city ?? "",
              state: data?.home?.state ?? "",
              postal_code: data?.home?.postal_code ?? "",
              country: data?.home?.country ?? null,
            },
            needs_sale: data?.home?.needs_sale,
            max_price: data?.home?.max_price,
            min_price: data?.home?.min_price,
            min_beds: data?.home?.min_beds,
            min_baths: data?.home?.min_baths,
            type: data?.home?.type?.id,
            quality: data?.home?.quality?.id,
            auto_complete: data?.home?.locations?.map((loc) => ({
              label: loc?.location,
              value: loc?.id,
            })),
            features: features?.reduce(
              (acc, feature) => ({
                ...acc,
                [feature.id]:
                  data?.home?.features?.find(
                    (feat) => feat?.feature.id === feature.id
                  )?.value ?? null,
              }),
              {} as any
            ),
            additional_notes: data?.home?.additional_notes,
            found_home: data?.home?.found_home,
            oncollaboption: data?.home?.oncollaboption,
          }}
          validationSchema={ManageHomeSchema}
          onSubmit={onSubmit}
        >
          <ManageHomeForm />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageHome;
