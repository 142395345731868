import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AddLocationComp from "../../Components/Forms/AddLocationForm";
import { CreateLocation } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateLocation as CreateLocationQuery,
  CreateLocationVariables,
} from "../../types/CreateLocation";
import CityLocationSchema from "../../Validators/CityLocations";

const AddLocation = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  const [createLocation] = useMutation<
    CreateLocationQuery,
    CreateLocationVariables
  >(CreateLocation);
  //submit call to send data
  async function submitForm({ name }: any) {
    const variables = {
      input: {
        location: name,
      },
    };

    const { data } = await createLocation({ variables });

    //if sucess then show message
    if (data?.createCityLocation) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Location successfully created!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Locations</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={submitForm}
        validationSchema={CityLocationSchema}
      >
        <AddLocationComp />
      </Formik>
    </Container>
  );
};

export default AddLocation;
