import { gql } from "@apollo/client";

/* Fragments */

export const Timestamps = gql`
  fragment Timestamps on HasTimestamps {
    created_at
    updated_at
    deleted_at
  }
`;

export const Address = gql`
  fragment Address on HasAddress {
    address_1
    address_2
    city
    state
    country
    postal_code
  }
`;

export const BasicCityLocationInfo = gql`
  fragment BasicCityLocationInfo on CityLocations {
    id
    location
  }
`;

export const BasicUserInfo = gql`
  fragment BasicUserInfo on User {
    id
    first_name
    last_name
    email
    role
  }
`;

export const BasicEditorsInfo = gql`
  fragment BasicEditorsInfo on HasEditors {
    created_by {
      ...BasicUserInfo
    }
    updated_by {
      ...BasicUserInfo
    }
    deleted_by {
      ...BasicUserInfo
    }
  }
  ${BasicUserInfo}
`;

export const EditorsInfo = gql`
  fragment EditorsInfo on HasEditors {
    created_by {
      ...BasicUserInfo
      ...Timestamps
    }
    updated_by {
      ...BasicUserInfo
      ...Timestamps
    }
    deleted_by {
      ...BasicUserInfo
      ...Timestamps
    }
  }

  ${BasicUserInfo}
  ${Timestamps}
`;

export const BasicOrganizationInfo = gql`
  fragment BasicOrganizationInfo on Organization {
    id
    name
    website_url
    phone_number
    logo_id
  }
`;

export const OrgSettingsInfo = gql`
  fragment OrgSettingsInfo on OrganizationSetting {
    key
    value
  }
`;

export const BasicHometypeInfo = gql`
  fragment BasicHometypeInfo on HomeType {
    id
    name
    description
  }
`;

export const BasicHomeQualitiesInfo = gql`
  fragment BasicHomeQualitiesInfo on HomeQuality {
    id
    name
    description
    positivity
  }
`;

export const BasicHomeFeaturesInfo = gql`
  fragment BasicHomeFeaturesInfo on Feature {
    id
    name
    description
  }
`;

export const BasicHomeLocationInfo = gql`
  fragment BasicHomeLocationInfo on HomeLocation {
    location
  }
`;

export const OrganizationFeatures = gql`
  fragment OrganizationFeatures on Organization {
    features {
      id
      name
      description
    }
  }
`;

export const OrganizationQualities = gql`
  fragment OrganizationQualities on Organization {
    homeQualities {
      id
      name
      description
      positivity
    }
  }
`;

export const OrganizationHomeTypes = gql`
  fragment OrganizationHomeTypes on Organization {
    homeTypes {
      id
      name
      description
    }
  }
`;

export const BasicDirectoryCategoryInfo = gql`
  fragment BasicDirectoryCategoryInfo on DirectoryCategory {
    id
    name
  }
`;

export const BasicDirectoryInfo = gql`
  fragment BasicDirectoryInfo on Directory {
    id
    first_name
    last_name
    email
    phone_number
    ...Address
    category(trashed: WITH) {
      ...BasicDirectoryCategoryInfo
    }
  }
  ${Address}
  ${BasicDirectoryCategoryInfo}
`;

export const DirectoryCategoryInfo = gql`
  fragment DirectoryCategoryInfo on DirectoryCategory {
    ...BasicDirectoryCategoryInfo
    directories {
      ...BasicDirectoryInfo
    }
  }
  ${BasicDirectoryCategoryInfo}
  ${BasicDirectoryInfo}
`;

export const OrganizationInfo = gql`
  fragment OrganizationInfo on Organization {
    ...BasicOrganizationInfo
    ...EditorsInfo
    ...Timestamps
    ...Address
    settings {
      ...OrgSettingsInfo
    }
    directoryCategories {
      ...DirectoryCategoryInfo
    }
    ...OrganizationFeatures
    ...OrganizationHomeTypes
    ...OrganizationQualities
    directories {
      ...BasicDirectoryInfo
    }
    active
  }
  ${BasicOrganizationInfo}
  ${EditorsInfo}
  ${Timestamps}
  ${Address}
  ${OrgSettingsInfo}
  ${OrganizationFeatures}
  ${OrganizationHomeTypes}
  ${OrganizationQualities}
  ${DirectoryCategoryInfo}
  ${BasicDirectoryInfo}
`;

export const UserInfo = gql`
  fragment UserInfo on User {
    ...BasicUserInfo
    ...EditorsInfo
    ...Timestamps
    role
    phone_number
    organization {
      ...OrganizationInfo
    }
  }
  ${BasicUserInfo}
  ${EditorsInfo}
  ${Timestamps}
  ${OrganizationInfo}
`;

export const BasicHomeInfo = gql`
  fragment BasicHomeInfo on Home {
    id
    first_name
    last_name
    phone_number
    email
    locations {
      id
      location
    }
    found_home {
      ...Address
    }
    min_beds
    min_baths
    additional_notes
    renting
    max_price
    created_at
    oncollaboption
  }
  ${Address}
`;

export const HomeInfo = gql`
  fragment HomeInfo on Home {
    ...BasicHomeInfo
    ...Address

    organization {
      ...BasicOrganizationInfo
    }

    type {
      id
      name
    }

    features {
      feature {
        id
        name
      }
      value
    }
    quality {
      id
      name
    }
    locations {
      id
      location
    }
    min_price
    max_price
    min_beds
    min_baths
    needs_sale
    additional_notes
    oncollaboption
  }

  ${BasicHomeInfo}
  ${BasicOrganizationInfo}
  ${Address}
`;

export const MinimalPaginatorInfo = gql`
  fragment MinimalPaginatorInfo on PaginatorInfo {
    hasMorePages
    currentPage
    lastPage
    perPage
  }
`;

export const DetailedPaginatorInfo = gql`
  fragment DetailedPaginatorInfo on PaginatorInfo {
    ...MinimalPaginatorInfo
    total
  }
  ${MinimalPaginatorInfo}
`;

/* Queries */

export const GetCountryAndProvinces = gql`
  query GetCountryAndProvinces {
    countries {
      name
      value
      provinces {
        name
        abbreviation
      }
    }
  }
`;

export const GetLocations = gql`
  query GetLocations {
    citylocation {
      id
      location
    }
  }
`;

export const GetPaginatedLocations = gql`
  query GetPaginatedLocations($first: Int, $page: Int) {
    citylocations(first: $first, page: $page) {
      data {
        ...BasicCityLocationInfo
      }
      paginatorInfo {
        ...MinimalPaginatorInfo
      }
    }
  }
  ${MinimalPaginatorInfo}
  ${BasicCityLocationInfo}
`;

export const GetUser = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserInfo
    }
  }
  ${UserInfo}
`;

export const GetSelf = gql`
  query GetSelf {
    self {
      ...UserInfo
    }
  }
  ${UserInfo}
`;

export const GetUsers = gql`
  query GetUsers($first: Int, $page: Int) {
    users(first: $first, page: $page) {
      data {
        ...BasicUserInfo
      }
      paginatorInfo {
        ...MinimalPaginatorInfo
      }
    }
  }
  ${BasicUserInfo}
`;

export const GetOrganization = gql`
  query GetOrganization($id: ID!, $userFirst: Int, $userPage: Int) {
    organization(id: $id) {
      ...OrganizationInfo

      users(first: $userFirst, page: $userPage) {
        data {
          ...BasicUserInfo
        }
        paginatorInfo {
          ...MinimalPaginatorInfo
        }
      }
      settings {
        ...OrgSettingsInfo
      }
      homeTypes {
        ...BasicHometypeInfo
      }
      homeQualities {
        ...BasicHomeQualitiesInfo
      }
      features {
        ...BasicHomeFeaturesInfo
      }
    }
  }
  ${MinimalPaginatorInfo}
  ${OrganizationInfo}
  ${BasicUserInfo}
  ${OrgSettingsInfo}
  ${BasicHometypeInfo}
  ${BasicHomeQualitiesInfo}
  ${BasicHomeFeaturesInfo}
`;

export const GetOrganizations = gql`
  query GetOrganizations($first: Int, $page: Int) {
    organizations(first: $first, page: $page) {
      data {
        ...BasicOrganizationInfo
      }
      paginatorInfo {
        ...MinimalPaginatorInfo
      }
    }
  }

  ${MinimalPaginatorInfo}
  ${BasicOrganizationInfo}
`;

export const GetHomes = gql`
  query GetHomes(
    $organization_id: ID!
    $first: Int!
    $page: Int
    $first_name: String
    $last_name: String
    $phone_number: String
    $email: String
    $renting: Boolean
    $locations: [HomeLocationSearch]
    $needs_sale: Boolean
    $max_price: Int
    $min_price: Int
    $min_beds: Int
    $min_baths: Int
    $type: ID
    $features: [HomeFeatureSearch!]
    $quality: ID
    $oncollaboption: Boolean
    $orderBy: [HomesOrderByOrderByClause!]
    $found_home: Boolean
  ) {
    organization(id: $organization_id) {
      id
      homes(
        first: $first
        page: $page
        first_name: $first_name
        last_name: $last_name
        phone_number: $phone_number
        email: $email
        renting: $renting
        locations: $locations
        needs_sale: $needs_sale
        max_price: $max_price
        min_price: $min_price
        min_baths: $min_baths
        min_beds: $min_beds
        type: $type
        features: $features
        quality: $quality
        oncollaboption: $oncollaboption
        orderBy: $orderBy
        found_home: $found_home
      ) {
        data {
          ...BasicHomeInfo
        }
        paginatorInfo {
          ...MinimalPaginatorInfo
        }
      }
    }
  }

  ${MinimalPaginatorInfo}
  ${BasicHomeInfo}
`;

export const GetHome = gql`
  query GetHome($id: ID!) {
    home(id: $id) {
      ...HomeInfo
    }
  }
  ${HomeInfo}
`;

export const GetDirectory = gql`
  query GetDirectory($id: ID!) {
    directory(id: $id) {
      ...BasicDirectoryInfo
    }
  }
  ${BasicDirectoryInfo}
`;

export const GetDirectoryInfo = gql`
  query GetDirectoryInfo {
    self {
      organization {
        directoryCategories {
          ...BasicDirectoryCategoryInfo
          directories {
            ...BasicDirectoryInfo
          }
        }
      }
    }
  }
  ${BasicDirectoryCategoryInfo}
  ${BasicDirectoryInfo}
`;

/* Mutations */
export const CreateOrganization = gql`
  mutation CreateOrganization($input: CreateOrganizationArgs!) {
    createOrganization(input: $input) {
      ...OrganizationInfo
    }
  }

  ${OrganizationInfo}
`;

export const UpdateOrganization = gql`
  mutation UpdateOrganization($input: UpdateOrganizationArgs!) {
    updateOrganization(input: $input) {
      ...OrganizationInfo
    }
  }

  ${OrganizationInfo}
`;
export const CreateUser = gql`
  mutation CreateUser($input: CreateUserArgs!) {
    createUser(input: $input) {
      ...UserInfo
    }
  }

  ${UserInfo}
`;

export const SetOrganizationSettings = gql`
  mutation SetOrganizationSettings(
    $input1: OrganizationSettingArgs!
    $input2: OrganizationSettingArgs!
    $input3: OrganizationSettingArgs!
  ) {
    output1: setOrganizationSetting(input: $input1) {
      id
    }
    output2: setOrganizationSetting(input: $input2) {
      id
    }
    output3: setOrganizationSetting(input: $input3) {
      ...OrganizationInfo
    }
  }
  ${OrganizationInfo}
`;

export const CreateHomeType = gql`
  mutation CreateHomeType($input: HomeTypeArgs!) {
    createHomeType(input: $input) {
      ...BasicHometypeInfo
    }
  }
  ${BasicHometypeInfo}
`;

export const CreateHomeQuality = gql`
  mutation CreateHomeQuality($input: HomeQualityArgs!) {
    createHomeQuality(input: $input) {
      ...BasicHomeQualitiesInfo
    }
  }
  ${BasicHomeQualitiesInfo}
`;

export const CreateHomeFeatures = gql`
  mutation CreateHomeFeatures($input: FeatureArgs!) {
    createFeature(input: $input) {
      ...BasicHomeFeaturesInfo
    }
  }
  ${BasicHomeFeaturesInfo}
`;

export const DeleteHomeType = gql`
  mutation DeleteHomeType($id: ID!) {
    deleteHomeType(id: $id) {
      ...BasicHometypeInfo
    }
  }
  ${BasicHometypeInfo}
`;

export const DeleteHomeFeature = gql`
  mutation DeleteHomeFeature($id: ID!) {
    deleteFeature(id: $id) {
      ...BasicHomeFeaturesInfo
    }
  }
  ${BasicHomeFeaturesInfo}
`;

export const DeleteHomeQuality = gql`
  mutation DeleteHomeQuality($id: ID!) {
    deleteHomeQuality(id: $id) {
      ...BasicHomeQualitiesInfo
    }
  }
  ${BasicHomeQualitiesInfo}
`;

export const CreateHome = gql`
  mutation CreateHome($input: HomeArgs!) {
    createHome(input: $input) {
      ...HomeInfo
    }
  }
  ${HomeInfo}
`;

export const DeleteHome = gql`
  mutation DeleteHome($id: ID!) {
    deleteHome(id: $id) {
      ...BasicHomeInfo
    }
  }
  ${BasicHomeInfo}
`;

export const UpdateHome = gql`
  mutation UpdateHome($input: UpdateHomeArgs!) {
    updateHome(input: $input) {
      ...BasicHomeInfo
    }
  }
  ${BasicHomeInfo}
`;

export const UpdateHomeType = gql`
  mutation UpdateHomeType($input: UpdateHomeTypeArgs!) {
    updateHomeType(input: $input) {
      ...BasicHometypeInfo
    }
  }
  ${BasicHometypeInfo}
`;

export const UpdateHomeQuality = gql`
  mutation UpdateHomeQuality($input: UpdateHomeQualityArgs!) {
    updateHomeQuality(input: $input) {
      ...BasicHomeQualitiesInfo
    }
  }
  ${BasicHomeQualitiesInfo}
`;

export const UpdateHomeFeature = gql`
  mutation UpdateHomeFeature($input: UpdateFeatureArgs!) {
    updateFeature(input: $input) {
      ...BasicHomeFeaturesInfo
    }
  }
  ${BasicHomeFeaturesInfo}
`;

export const UpdateUser = gql`
  mutation UpdateUser($input: UpdateUserArgs!) {
    updateUser(input: $input) {
      ...UserInfo
    }
  }
  ${UserInfo}
`;

export const CreateDirectoryCategory = gql`
  mutation CreateDirectoryCategory($input: CreateDirectoryCategoryInput!) {
    createDirectoryCategory(input: $input) {
      ...BasicDirectoryCategoryInfo
    }
  }
  ${BasicDirectoryCategoryInfo}
`;

export const CreateDirectory = gql`
  mutation CreateDirectory($input: CreateDirectoryInput) {
    createDirectory(input: $input) {
      ...BasicDirectoryInfo
    }
  }
  ${BasicDirectoryInfo}
`;

export const UpdateDirectories = gql`
  mutation UpdateDirectories($input: UpdateDirectoryInput!) {
    updateDirectory(input: $input) {
      ...BasicDirectoryInfo
    }
  }
  ${BasicDirectoryInfo}
`;
export const DeleteDirectories = gql`
  mutation DeleteDirectories($id: ID!) {
    deleteDirectory(id: $id) {
      ...BasicDirectoryInfo
    }
  }
  ${BasicDirectoryInfo}
`;

export const DeleteDirectoriesCategories = gql`
  mutation DeleteDirectoriesCategories($id: ID!) {
    deleteDirectoryCategory(id: $id) {
      ...BasicDirectoryCategoryInfo
    }
  }
  ${BasicDirectoryCategoryInfo}
`;

export const UpdateDirectoriesCategories = gql`
  mutation UpdateDirectoriesCategories($input: UpdateDirectoryCategoryInput!) {
    updateDirectoryCategory(input: $input) {
      ...BasicDirectoryCategoryInfo
    }
  }
  ${BasicDirectoryCategoryInfo}
`;

export const CreateHomeFoundEntry = gql`
  mutation CreateHomeFoundEntry($input: UpdateHomeArgs!) {
    updateHome(input: $input) {
      ...HomeInfo
    }
  }
  ${HomeInfo}
`;

export const CreateLocation = gql`
  mutation CreateLocation($input: CreateCityLocationInput!) {
    createCityLocation(input: $input) {
      ...BasicCityLocationInfo
    }
  }
  ${BasicCityLocationInfo}
`;

export const UpdateLocation = gql`
  mutation UpdateLocation($input: UpdateLocationArgs!) {
    updateCityLocation(input: $input) {
      ...BasicCityLocationInfo
    }
  }
  ${BasicCityLocationInfo}
`;
export const DeleteLocation = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      ...BasicCityLocationInfo
    }
  }
  ${BasicCityLocationInfo}
`;

export const DeleteHomeLocation = gql`
  mutation DeleteHomeLocation($id: [ID]!) {
    deleteHomeLocation(id: $id) {
      ...BasicHomeLocationInfo
    }
  }
  ${BasicHomeLocationInfo}
`;
