import Holder from "holderjs";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "./Components/Breadcrumbs";
import BSNavbar from "./Components/Navbar";
import RouteContainer from "./Components/RouteContainer";
import ToastArea from "./Components/ToastArea";
import { IToast } from "./Interfaces";
import { IRoute } from "./Routing/IRoute";
import { GetRoutePath, RouteContext } from "./Routing/Routing";
import { UserSource } from "./Sources/UserSource";

/** Fixes all placeholders in the application */
const Placeholders = () => {
  useLayoutEffect(() => {
    Holder.run({ images: document.getElementsByTagName("img") as any });
  });
  return <></>;
};

const App = () => {
  // The currently loaded route
  const [currentRoute, setCurrentRoute] = useState<IRoute>();
  const [loading, setLoading] = useState(false);
  const currentRoutes = useMemo(
    () => (currentRoute != null ? GetRoutePath(currentRoute) ?? [] : []),
    [currentRoute]
  );
  const [routeData, setRouteData] = useState<any>({});
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = function (toast: IToast) {
    setToasts((toasts) => ([] as IToast[]).concat(toasts).concat([toast]));
  };

  const removeToast = function (toast: IToast) {
    if (toasts.includes(toast))
      setToasts((toasts) => {
        let newToasts = toasts.slice();
        newToasts.splice(toasts.indexOf(toast), 1);
        return newToasts;
      });
  };

  return (
    <UserSource>
      <Placeholders />
      <Helmet defaultTitle="REA" titleTemplate="%s" />
      <RouteContext.Provider
        value={{
          currentRoutes,
          setCurrentRoute,
          loading,
          setLoading,
          routeData,
          setRouteData,
          toasts,
          addToast,
          removeToast,
        }}
      >
        <BSNavbar />
        <Breadcrumbs />
        <RouteContainer />
        <ToastArea />
      </RouteContext.Provider>
    </UserSource>
  );
};

export default App;
