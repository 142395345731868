import preval from "preval.macro";

/** The build stamp */
const { BUILD_STAMP, BUILD_TIME } = preval`
    const time = Buffer.alloc(4);
    const timestamp = new Date();
    time.writeUInt32BE(Math.floor(timestamp.getTime() / 1000));
    module.exports = { BUILD_STAMP: time.toString("hex"), BUILD_TIME: timestamp };` as {
  BUILD_STAMP: string;
  BUILD_TIME: Date;
};

/** The base URI for the Laravel API */
const API_BASE = preval`
    const root = process.env.API_BASE ?? "http://localhost:8000";
    module.exports = root;
` as string;

const DISABLED_FEATURES = ["VIDEO"];

const VERSION = "1.0.0";

export { VERSION, BUILD_STAMP, BUILD_TIME, API_BASE, DISABLED_FEATURES };
