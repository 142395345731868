import axios from "axios";
import { API_BASE } from "./Sources/BuildMetadata";

// Create the Axios instance with the proper config
const Axios = axios.create({
  baseURL: API_BASE,
  withCredentials: true,
});

export default Axios;
