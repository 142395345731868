import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NewContactForm from "../../Components/Forms/NewContactForm";
import { CreateDirectory } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateDirectoryVariables,
  CreateDirectory as CreateDirectoryQuery,
} from "../../types/CreateDirectory";
import NewContactSchema from "../../Validators/NewContactForm";

const NewDirectory = () => {
  const [createDirectory] = useMutation<
    CreateDirectoryQuery,
    CreateDirectoryVariables
  >(CreateDirectory);

  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  const onSubmit = async ({
    first_name,
    last_name,
    email,
    phone_number,
    location: { address_1, address_2, city, state, postal_code, country },
  }: any) => {
    const variables: CreateDirectoryVariables = {
      input: {
        first_name,
        last_name,
        email,
        phone_number,
        address_1,
        address_2,
        city,
        state,
        postal_code,
        country,
        organization: {
          connect: oid as string,
        },
        category: {
          connect: "1",
        },
      },
    };

    const { data } = await createDirectory({ variables });

    if (data?.createDirectory) {
      addToast({
        header: (
          <>
            <strong>Success!</strong>
          </>
        ),
        body: "Directory Contact successfully created!",
      });
      history.push(`/manage/directories/${data.createDirectory.id}`);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>New Contact</h1>
        </Col>
      </Row>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone_number: "",
          location: {
            address: "",
            address2: "",
            city: "",
            state: "",
            postal: "",
            country: "",
          },
          directoryCategory: "",
        }}
        validationSchema={NewContactSchema}
      >
        <NewContactForm />
      </Formik>
    </Container>
  );
};

export default NewDirectory;
