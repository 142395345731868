import { useFormikContext } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Form from "../Form";
import Loader from "../Loader";
import LocationForm from "../LocationForm";
import { GetHome as GetHomeQuery, GetHomeVariables } from "../../types/GetHome";
import { GetHome } from "../../Queries";
import { useQuery } from "@apollo/client";

const FoundHomeComp = (() => {
  const { dirty, isValid, isSubmitting } = useFormikContext();
  const { bid } = useParams<{ bid: string }>();

  const { data } = useQuery<GetHomeQuery, GetHomeVariables>(GetHome, {
    variables: {
      id: bid,
    },
  });

  function checkFoundHome() {
    if (data?.home?.found_home) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <Form className="mt-2">
      <Loader loaded={!isSubmitting} />
      <h2>Found Home information</h2>
      <LocationForm name="found_location" />
      <Form.Row>
        <Button
          type="submit"
          variant="primary"
          className={`${
            !(dirty && isValid) ? "disabled-btn" : ""
          } mr-1 col-auto`}
          disabled={checkFoundHome()}
        >
          Submit
        </Button>
      </Form.Row>
    </Form>
  );
}) as React.FC<{}>;

export default FoundHomeComp;
