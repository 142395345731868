import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "../Form";
import { useFormikContext } from "formik";
import Loader from "../Loader";

const SettingsComp = () => {
  const { isValid, dirty, isSubmitting } = useFormikContext<any>();

  return (
    <Form>
      <Loader loaded={!isSubmitting} />
      <Form.Row>
        <Col>Price Interval</Col>
        <Form.Field
          type="number"
          name="priceInterval"
          step="1000"
          min="0"
          prepend="$"
          column
        />
      </Form.Row>
      <Form.Row>
        <Col>Maximum Note Length</Col>
        <Form.Field
          type="number"
          name="maxNoteLength"
          step="1000"
          min="0"
          append="chars"
          column
        />
      </Form.Row>
      <Form.Row>
        <Col>
          Auto Logout
          <br />
          <small className="text-muted ">(0 to disable)</small>
        </Col>
        <Form.Field
          type="number"
          name="autoLogoutDelay"
          step="1000"
          min="0"
          append="min"
          column
        />
      </Form.Row>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid || !dirty}
                className="mr-1"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default SettingsComp;
