/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * A country of the world.
 */
export enum Country {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum FeatureValue {
  N = "N",
  NA = "NA",
  Y = "Y",
}

/**
 * Allowed column names for the `orderBy` argument on the query `homes`.
 */
export enum HomesOrderByColumn {
  CREATED_AT = "CREATED_AT",
  MAX_PRICE = "MAX_PRICE",
}

/**
 * A role a user may have on the site
 */
export enum Role {
  Client = "Client",
  OrgAdmin = "OrgAdmin",
  Realtor = "Realtor",
  SysOp = "SysOp",
}

/**
 * The available directions for ordering a list of records.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface CreateCityLocationInput {
  location: string;
}

export interface CreateDirectoryCategoryInput {
  name: string;
  organization: OrganizationBelongsTo;
}

export interface CreateDirectoryInput {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  country: Country;
  postal_code: string;
  organization: OrganizationBelongsTo;
  category: DirectoryCategoryBelongsTo;
}

export interface CreateOrganizationArgs {
  name: string;
  phone_number: string;
  website_url?: string | null;
  logo_id?: string | null;
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  country: Country;
  postal_code: string;
}

export interface CreateUserArgs {
  email: string;
  organization: OrganizationBelongsTo;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: Role;
  password: string;
}

export interface DirectoryCategoryBelongsTo {
  connect: string;
}

export interface FeatureArgs {
  name: string;
  description?: string | null;
  organization: OrganizationBelongsTo;
}

export interface FeatureBelongsTo {
  connect: string;
}

export interface HomeArgs {
  organization: OrganizationBelongsTo;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: Country | null;
  postal_code?: string | null;
  renting?: boolean | null;
  first_name: string;
  last_name?: string | null;
  phone_number?: string | null;
  email?: string | null;
  needs_sale?: boolean | null;
  max_price?: number | null;
  min_price?: number | null;
  min_beds?: number | null;
  min_baths?: number | null;
  additional_notes?: string | null;
  oncollaboption?: boolean | null;
  type?: HomeTypeBelongsTo | null;
  quality?: HomeQualityBelongsTo | null;
  features?: HomeFeatureHasMany | null;
  locations?: HomeLocationHasMany | null;
  found_home?: HomeFoundHasOne | null;
}

export interface HomeFeatureArgs {
  feature: FeatureBelongsTo;
  value: FeatureValue;
}

export interface HomeFeatureHasMany {
  create: HomeFeatureArgs[];
  update?: HomeFeatureArgs[] | null;
}

export interface HomeFeatureSearch {
  feature_id: string;
  value: FeatureValue;
}

export interface HomeFoundArgs {
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  country: Country;
  postal_code: string;
}

export interface HomeFoundHasOne {
  create?: HomeFoundArgs | null;
  update?: UpdateHomeFoundArgs | null;
}

export interface HomeLocationArgs {
  location: string;
}

export interface HomeLocationHasMany {
  create?: HomeLocationArgs[] | null;
  update?: UpdateHomeLocationArgs[] | null;
}

export interface HomeLocationSearch {
  id: string;
  location: string;
}

export interface HomeQualityArgs {
  name: string;
  description?: string | null;
  positivity: number;
  organization: OrganizationBelongsTo;
}

export interface HomeQualityBelongsTo {
  connect?: string | null;
  update?: HomeQualityArgs | null;
}

export interface HomeTypeArgs {
  name: string;
  description?: string | null;
  organization: OrganizationBelongsTo;
}

export interface HomeTypeBelongsTo {
  connect?: string | null;
  update?: HomeTypeArgs | null;
}

/**
 * Order by clause for the `orderBy` argument on the query `homes`.
 */
export interface HomesOrderByOrderByClause {
  field: HomesOrderByColumn;
  order: SortOrder;
}

export interface OrganizationBelongsTo {
  connect?: string | null;
}

export interface OrganizationSettingArgs {
  organization_id: string;
  key: string;
  value?: string | null;
}

export interface UpdateDirectoryCategoryInput {
  id: string;
  name?: string | null;
}

export interface UpdateDirectoryInput {
  id: string;
  first_name?: string | null;
  last_name: string;
  phone_number?: string | null;
  email?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: Country | null;
  postal_code?: string | null;
  category: DirectoryCategoryBelongsTo;
}

export interface UpdateFeatureArgs {
  id: string;
  name?: string | null;
  description?: string | null;
}

export interface UpdateHomeArgs {
  id: number;
  organization?: OrganizationBelongsTo | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: Country | null;
  postal_code?: string | null;
  renting?: boolean | null;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
  email?: string | null;
  needs_sale?: boolean | null;
  max_price?: number | null;
  min_price?: number | null;
  min_beds?: number | null;
  min_baths?: number | null;
  additional_notes?: string | null;
  oncollaboption?: boolean | null;
  type?: HomeTypeBelongsTo | null;
  quality?: HomeQualityBelongsTo | null;
  features?: HomeFeatureHasMany | null;
  locations?: HomeLocationHasMany | null;
  found_home?: HomeFoundHasOne | null;
}

export interface UpdateHomeFoundArgs {
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: Country | null;
  postal_code?: string | null;
}

export interface UpdateHomeLocationArgs {
  id: string;
  home_id: string;
  location?: string | null;
}

export interface UpdateHomeQualityArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  positivity?: number | null;
}

export interface UpdateHomeTypeArgs {
  id: string;
  name?: string | null;
  description?: string | null;
}

export interface UpdateLocationArgs {
  id: string;
  location?: string | null;
}

export interface UpdateOrganizationArgs {
  id: string;
  name?: string | null;
  phone_number?: string | null;
  website_url?: string | null;
  logo_id?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: Country | null;
  postal_code?: string | null;
}

export interface UpdateUserArgs {
  id: number;
  email?: string | null;
  organization?: OrganizationBelongsTo | null;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
  role?: Role | null;
  password?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
