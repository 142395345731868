import React, { useContext } from "react";
import { Dropdown, Nav, Navbar as BSNavbar } from "react-bootstrap";
import { LogIn, LogOut, Settings } from "react-feather";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Logo from "../res/reaper_logo.svg";
import LogInImage from "../res/login.svg";
import { API_BASE } from "../Sources/BuildMetadata";
import { UserContext } from "../Sources/UserSource";

const Navbar = () => {
  const userContext = useContext(UserContext);

  return (
    <BSNavbar expand="md">
      {userContext.loggedIn ? (
        <BSNavbar.Brand as={Link} to="/">
          <img
            src={
              userContext.user!.organization
                ? `${API_BASE}/${userContext.user!.organization!.logo_id}`
                : Logo
            }
            alt={userContext.user!.organization?.name ?? "Reap"}
            width={40}
            className="mr-3"
          />
          <span className="text-primary">
            {userContext.user!.organization?.name ?? "Reap"}
          </span>
        </BSNavbar.Brand>
      ) : (
        !userContext.loading && (
          <BSNavbar.Brand as={Link} to="/login">
            <LogIn className="text-primary mr-2" width="40" />
            <span className="text-primary">Log In</span>
          </BSNavbar.Brand>
        )
      )}
      <Helmet>
        <link
          rel="icon"
          href={
            userContext.loggedIn
              ? userContext.user?.organization
                ? `${API_BASE}/${userContext.user!.organization!.logo_id}`
                : Logo
              : LogInImage
          }
        />
      </Helmet>
      <BSNavbar.Toggle aria-controls="navbar" />
      <BSNavbar.Collapse id="navbar">
        <Nav className="ml-auto py-1">
          {userContext.loggedIn && (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="h-100 remove-text-transform"
              >
                {userContext.user!.email}
              </Dropdown.Toggle>
              <Dropdown.Menu align="right" className="">
                <Dropdown.ItemText>
                  <b>
                    {userContext.user!.first_name} {userContext.user!.last_name}
                  </b>
                  <br />
                  <span>{userContext.user!.role}</span>
                </Dropdown.ItemText>
                <Dropdown.Item as={Link} to="/user" className="text-primary">
                  <Settings /> {` `}
                  Account Settings
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/logout" className="text-primary">
                  <LogOut /> {` `}
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {!userContext.loggedIn && (
            <Nav.Link as={Link} to="/login" className="text-primary">
              Log in
            </Nav.Link>
          )}
        </Nav>
      </BSNavbar.Collapse>
    </BSNavbar>
  );
};

export default Navbar;
