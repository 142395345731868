import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ManageDirectoryComp from "../../Components/Forms/ManageDirectoryForm";
import Loader from "../../Components/Loader";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";
import {
  DeleteDirectoriesCategories as DeleteDirectoriesCategoriesquery,
  DeleteDirectoriesCategoriesVariables,
} from "../../types/DeleteDirectoriesCategories";
import {
  DeleteDirectoriesCategories,
  GetOrganization,
  UpdateDirectoriesCategories,
} from "../../Queries";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";
import {
  UpdateDirectoriesCategories as UpdateDirectoriesCategoriesquery,
  UpdateDirectoriesCategoriesVariables,
} from "../../types/UpdateDirectoriesCategories";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { RouteContext } from "../../Routing/Routing";

const ManageDirectoryCategory = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  let { dcid } = useParams<{ dcid: string }>();

  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  let type_id =
    data?.organization?.directoryCategories.find(
      (dircat) => dircat.id === (dcid as string)
    ) ?? null;

  //call to delete home type features
  //call to delete directory contact
  const [deleteDirectoriesCategory] = useMutation<
    DeleteDirectoriesCategoriesquery,
    DeleteDirectoriesCategoriesVariables
  >(DeleteDirectoriesCategories);

  const [updateDirectoryCatrgoies] = useMutation<
    UpdateDirectoriesCategoriesquery,
    UpdateDirectoriesCategoriesVariables
  >(UpdateDirectoriesCategories);

  async function handleDelete() {
    const variables: DeleteDirectoriesCategoriesVariables = {
      id: type_id?.id as string,
    };
    const { data } = await deleteDirectoriesCategory({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Directory Category successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  async function submitForm({ name }: any) {
    const variables = {
      input: {
        id: type_id?.id as string,
        name,
      },
    };
    const { data } = await updateDirectoryCatrgoies({ variables });

    //if sucess then show message
    if (data?.updateDirectoryCategory) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Directory category successfully updated!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Directory</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!type_id?.id}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            organization: "",
            name: type_id?.name ?? "",
          }}
          onSubmit={submitForm}
          validationSchema={ManageCompSchema}
        >
          <ManageDirectoryComp />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageDirectoryCategory;
