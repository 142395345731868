import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "react-bootstrap";

export interface IOption {
  value: string;
  label: string;
  variant?: string;
}

const FormTabRadio = (({ options, ...props }) => {
  return (
    <ToggleButtonGroup type="radio" className="d-flex" {...props}>
      {options?.map((option) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          variant={option.variant ?? "outline-primary"}
          className="w-100"
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}) as React.FC<ToggleButtonGroupProps<any> & { options?: IOption[] }>;

export default FormTabRadio;
