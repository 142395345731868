import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect, useMemo } from "react";
import { Button, Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Edit2, Plus } from "react-feather";
import { Link, useHistory, useParams } from "react-router-dom";
import GeneralOrganization from "../../Components/Forms/GeneralOrganization";
import SettingsComp from "../../Components/Forms/SettingsForm";
import Loader from "../../Components/Loader";
import { IGeneralSettingsOrganizationForm } from "../../Interfaces";
import {
  GetOrganization,
  SetOrganizationSettings,
  UpdateOrganization,
} from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";
import {
  SetOrganizationSettings as SetOrganizationSettingsQuery,
  SetOrganizationSettingsVariables,
} from "../../types/SetOrganizationSettings";
import ManageOrgSchema from "../../Validators/ManageOrganization";
import SettingsSchema from "../../Validators/Settings";
import NotFound from "../NotFound";
import { GetPaginatedLocations as GetPaginatedLocationsQuery } from "../../types/GetPaginatedLocations";
import { GetPaginatedLocations } from "../../Queries";
import Pagination from "../../Components/Pagination";
import {
  UpdateOrganization as UpdateOrganizationQuery,
  UpdateOrganizationVariables,
} from "../../types/UpdateOrganization";

const ManageOrganization = () => {
  const { setRouteData } = useContext(RouteContext);
  const { oid } = useParams<{ oid: string }>();
  const history = useHistory();
  const { data: loc, refetch } = useQuery<GetPaginatedLocationsQuery>(
    GetPaginatedLocations,
    {
      variables: {
        first: 10,
      },
      partialRefetch: true,
      returnPartialData: false,
      fetchPolicy: "network-only",
    }
  );

  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  const {
    name,
    phone_number,
    website_url,
    logo_id,
    address_1,
    address_2,
    city,
    state,
    postal_code,
    country,
  } = data?.organization! ?? {};

  useEffect(() => {
    setRouteData({ oid });
  }, [oid, setRouteData]);

  ///*** Global Settings code ***///

  //set Organization Id
  let organization_id: string = oid;

  //Update message
  const { addToast } = useContext(RouteContext);

  const paginationInfo = loc?.citylocations?.paginatorInfo;

  //Update mutation query
  const [updateSetting] = useMutation<
    SetOrganizationSettingsQuery,
    SetOrganizationSettingsVariables
  >(SetOrganizationSettings);

  const orgSettings = useMemo(() => {
    return data?.organization?.settings.reduce((acc, val) => {
      acc[val.key] = val.value;
      return acc;
    }, {} as any);
  }, [data?.organization?.settings]);

  //Update mutation query
  const [updateOrg] = useMutation<
    UpdateOrganizationQuery,
    UpdateOrganizationVariables
  >(UpdateOrganization);

  //Initial binding of settings values of Org from database
  let priceInterval = orgSettings?.["priceInterval"] ?? 10000;
  let maxNoteLength = orgSettings?.["maxNoteLength"] ?? 1000;
  let autoLogoutDelay = orgSettings?.["autoLogoutDelay"] ?? 0;

  //call to update database with values
  async function submitForm({
    priceInterval,
    maxNoteLength,
    autoLogoutDelay,
  }: IGeneralSettingsOrganizationForm) {
    const variables = {
      input1: {
        organization_id,
        value: priceInterval,
        key: "priceInterval",
      },
      input2: {
        organization_id,
        value: maxNoteLength,
        key: "maxNoteLength",
      },
      input3: {
        organization_id,
        value: autoLogoutDelay,
        key: "autoLogoutDelay",
      },
    };

    const { data } = await updateSetting({ variables });

    // Show message once data updated
    if (data?.output1) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Organization settings successfully updated!",
      });
    }
  }

  // Update Organization
  async function updateForm({
    name,
    phone_number,
    website_url,
    location,
    logo_id,
  }: any) {
    const variables = {
      input: {
        id: oid,
        name,
        phone_number,
        website_url,
        logo_id,
        ...location,
      },
    };

    const { data } = await updateOrg({ variables });

    if (data?.updateOrganization) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Organization successfully updated!",
      });
      history.push(`/manage/organizations/${data?.updateOrganization.id}`);
    }
  }

  return (
    <Container fluid>
      <Loader loaded={!loading || data != null} key={oid}>
        {data?.organization == null ? (
          <NotFound />
        ) : (
          <>
            <Row>
              <Col>
                <h1>Manage Organization</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Tabs
                    className="table-responsive"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Tab eventKey="general" title="General">
                      <Formik
                        initialValues={{
                          name,
                          phone_number,
                          website_url,
                          logo_id,
                          location: {
                            address_1,
                            address_2,
                            city,
                            state,
                            postal_code,
                            country,
                          },
                        }}
                        onSubmit={updateForm}
                        validationSchema={ManageOrgSchema}
                      >
                        <GeneralOrganization />
                      </Formik>
                    </Tab>
                    <Tab eventKey="users" title="Users">
                      <Row>
                        <Col>
                          <div className="table-responsive">
                            <Table hover>
                              <thead>
                                <tr>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Email</th>
                                  <th>Role</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.organization?.users?.data?.map(
                                  (user) => (
                                    <tr key={user.id}>
                                      <td>{user.first_name}</td>
                                      <td>{user.last_name}</td>
                                      <td>{user.email}</td>
                                      <td>{user.role}</td>
                                      <td>
                                        <Link to={`/manage/users/${user.id}`}>
                                          <Edit2 size={16} />
                                        </Link>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/users/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="homeTypes" title="Home Types">
                      <Row>
                        <Col>
                          <Table hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.organization?.homeTypes.map((hometype) => (
                                <tr key={data?.organization?.id}>
                                  <td>{hometype.name}</td>
                                  <td>
                                    <Link
                                      to={`/manage/hometypes/${hometype.id}`}
                                    >
                                      <Edit2 size={16} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/hometypes/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="qualities" title="Qualities">
                      <Row>
                        <Col>
                          <Table hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.organization?.homeQualities.map(
                                (homequalities) => (
                                  <tr key={data?.organization?.id}>
                                    <td>{homequalities.name}</td>
                                    <td>
                                      <Link
                                        to={`/manage/qualities/${homequalities.id}`}
                                      >
                                        <Edit2 size={16} />
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/qualities/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="features" title="Features">
                      <Row>
                        <Col>
                          <Table hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.organization?.features.map(
                                (homefeatures) => (
                                  <tr key={data?.organization?.id}>
                                    <td>{homefeatures.name}</td>
                                    <td>
                                      <Link
                                        to={`/manage/features/${homefeatures.id}`}
                                      >
                                        <Edit2 size={16} />
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/features/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="locations" title="Locations">
                      <Row className="mt-4">
                        <Col>
                          <Pagination
                            paginatorInfo={paginationInfo}
                            refetch={refetch}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Table hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loc?.citylocations?.data?.map((location) => (
                                <tr key={data?.organization?.id}>
                                  <td>{location.location}</td>
                                  <td>
                                    <Link
                                      to={`/manage/locations/${location.id}`}
                                    >
                                      <Edit2 size={16} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/locations/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="directoryCategories"
                      title="Directory Categories"
                    >
                      <Row>
                        <Col>
                          <div className="table-responsive">
                            <Table hover>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.organization?.directoryCategories.map(
                                  (category) => (
                                    <tr key={data?.organization?.id}>
                                      <td>{category.name}</td>
                                      <td>
                                        <Link
                                          to={`/manage/directorycategories/${category.id}`}
                                        >
                                          <Edit2 size={16} />
                                        </Link>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/directorycategories/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="directories" title="Directory Contacts">
                      <Row>
                        <Col>
                          <div className="table-responsive">
                            <Table hover>
                              <thead>
                                <tr>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Email</th>
                                  <th>Phone Number</th>
                                  <th>Category</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.organization?.directories.map(
                                  (directory) => (
                                    <tr key={data?.organization?.id}>
                                      <td>{directory.first_name}</td>
                                      <td>{directory.last_name}</td>
                                      <td>{directory.email}</td>
                                      <td>{directory.phone_number}</td>
                                      <td>
                                        {directory.category?.name ?? "Null"}
                                      </td>
                                      <td>
                                        <Link
                                          to={`/manage/directories/${directory.id}`}
                                        >
                                          <Edit2 size={16} />
                                        </Link>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to="/manage/directories/new"
                            variant="success"
                          >
                            <Plus /> Add New
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="settings" title="Settings" className="mt-4">
                      <Formik
                        initialValues={{
                          priceInterval,
                          maxNoteLength,
                          autoLogoutDelay,
                        }}
                        onSubmit={submitForm}
                        validationSchema={SettingsSchema}
                      >
                        <SettingsComp />
                      </Formik>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Loader>
    </Container>
  );
};

export default ManageOrganization;
