import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ManageHomeTypeComp from "../../Components/Forms/ManageHomeTypeForm";
import { IHomeTypeForm } from "../../Interfaces";
import { CreateHomeType } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateHomeType as CreateHomeTypeData,
  CreateHomeTypeVariables,
} from "../../types/CreateHomeType";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";

const ManageHomeType = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  //call to create and delete home type features
  const [createHomeType] = useMutation<
    CreateHomeTypeData,
    CreateHomeTypeVariables
  >(CreateHomeType);

  //submit call to send data
  async function submitForm({ name, description }: IHomeTypeForm) {
    const variables = {
      input: {
        organization: { connect: oid },
        name,
        description: "",
      },
    };

    const { data } = await createHomeType({ variables });

    //if sucess then show message
    if (data?.createHomeType) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Type successfully created!",
      });
      history.push(`/manage/hometypes/${data?.createHomeType.id}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Home Type</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{
          organization: "",
          name: "",
          description: "",
        }}
        onSubmit={submitForm}
        validationSchema={ManageCompSchema}
      >
        <ManageHomeTypeComp />
      </Formik>
    </Container>
  );
};

export default ManageHomeType;
