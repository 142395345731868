import React, { useEffect, useMemo } from "react";
import { GetLocations as GetLocationsQuery } from "../../types/GetLocations";
import { GetLocations } from "../../Queries";
import { GetHome as GetHomeQuery, GetHomeVariables } from "../../types/GetHome";
import { GetHome } from "../../Queries";
import { useQuery } from "@apollo/client";
import Form from "../../Components/Form";
import CreatableSelect from "react-select/creatable";

import { useField } from "formik";
import { useHistory, useParams } from "react-router-dom";

const AutoComplete = (({ name }) => {
  const { data } = useQuery<GetLocationsQuery>(GetLocations, {
    fetchPolicy: "cache-and-network",
  });
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { bid } = useParams<{ bid: string }>();
  const history = useHistory();

  useEffect(() => {}, [bid, history]);
  const { data: HomeLoc } = useQuery<GetHomeQuery, GetHomeVariables>(GetHome, {
    variables: {
      id: bid ?? "",
    },
  });

  const locations =
    useMemo(() => {
      return (
        data?.citylocation?.map((val) => ({
          label: val.location,
          value: val.id,
        })) ?? []
      );
    }, [data]) ?? [];

  const options =
    HomeLoc?.home?.locations?.map((val) => ({
      label: val?.location,
      value: val?.id,
    })) ?? [];

  return (
    <div>
      <Form>
        <div>
          {meta.touched && meta.error && (
            <div style={{ color: "red", marginTop: ".5rem" }}>{meta.error}</div>
          )}
          <CreatableSelect
            {...field}
            isMulti
            onChange={(e) => {
              setValue(e);
            }}
            options={locations}
            onBlur={() => helpers.setTouched(true)}
            defaultValue={options}
            isValidNewOption={() => false}
            isInvalid={!!(meta.touched && meta.error)}
          />
        </div>
      </Form>
    </div>
  );
}) as React.FC<{
  name: string;
}>;

export default AutoComplete;
