import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IGeneralOrganizationForm } from "../../Interfaces";
import GeneralOrganization from "../../Components/Forms/GeneralOrganization";
import { CreateOrganization } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateOrganization as CreateOrganizationData,
  CreateOrganizationVariables,
} from "../../types/CreateOrganization";
import { Country } from "../../types/globalTypes";
import ManageOrgSchema from "../../Validators/ManageOrganization";

const NewOrganization = () => {
  const history = useHistory();
  const { addToast } = useContext(RouteContext);

  const [createOrg] = useMutation<
    CreateOrganizationData,
    CreateOrganizationVariables
  >(CreateOrganization);

  async function submitForm({
    name,
    phone_number,
    website_url,
    location,
    logo_id,
  }: IGeneralOrganizationForm) {
    const variables = {
      input: {
        name,
        phone_number,
        website_url,
        logo_id,
        ...location,
      },
    };

    const { data } = await createOrg({ variables });

    if (data?.createOrganization) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Organization successfully created!",
      });
      history.push(`/manage/organizations/${data?.createOrganization.id}`);
    }
  }

  return (
    <Container fluid>
      <Formik
        initialValues={{
          name: "",
          website_url: "",
          phone_number: "",
          logo_id: "logos/default.png",
          location: {
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            postal_code: "",
            country: Country.CA,
          },
        }}
        onSubmit={submitForm}
        validationSchema={ManageOrgSchema}
      >
        <GeneralOrganization />
      </Formik>
    </Container>
  );
};

export default NewOrganization;
