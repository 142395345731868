import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { API_BASE } from "../Sources/BuildMetadata";

const CriticalError = (({ err, stage }) => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h1>Critical Error</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            An unexpected fatal error occurred while loading the application.
            <br />
            <br />
            Please contact the administrator with this error:
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <b>Stage:</b>
        </Col>
        <Col>{stage}</Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <b>Error:</b>
        </Col>
        <Col as="pre">{err?.stack ?? err?.toString() ?? err + ""}</Col>
      </Row>
      <Row>
        <Col>Try refreshing to see if it will fix the problem.</Col>
      </Row>
      {stage === "CSRF setup" && (
        <Row>
          <Col>
            <br />
            <b>Notes to developers:</b>
            <ul>
              <li>
                Ensure <code>rea-backend</code> is running and available
              </li>
              <li>
                The current API_BASE is <code>{API_BASE}</code>. If this is
                incorrect, remember to change it when building.
              </li>
            </ul>
          </Col>
        </Row>
      )}
    </Container>
  );
}) as React.FC<{ err: any; stage: string }>;

export default CriticalError;
