import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Reaper from "../res/reaper_logo.svg";
import DeveloperMenu from "../Views/Admin/DeveloperMenu";
import { VERSION, BUILD_STAMP } from "../Sources/BuildMetadata";

/** Used for unlocking Developer Mode on production */
const KONAMI_CODE = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
];

const Footer = () => {
  const [devModeUnlocked, setDevModeUnlocked] = useState(
    process.env.NODE_ENV === "development"
  );
  const [devModal, setDevModal] = useState(false);

  useEffect(() => {
    let keyPressQueue: string[] = [];

    const handleKeyPress = (evt: KeyboardEvent) => {
      keyPressQueue.push(evt.key);
      keyPressQueue = keyPressQueue.slice(-10);

      if (keyPressQueue.length === 10) {
        for (let i = 0; i < keyPressQueue.length; i++) {
          if (keyPressQueue[i] !== KONAMI_CODE[i]) return;
        }
        setDevModeUnlocked(true);
      }
    };

    document.body.addEventListener("keydown", handleKeyPress, true);

    return () =>
      document.body.removeEventListener("keydown", handleKeyPress, true);
  });

  return (
    <>
      <footer className="page-footer bg-secondary">
        <Row
          className="vw-100 px-0"
          style={{ marginLeft: "-17px", marginRight: "-17px" }}
        >
          {devModeUnlocked && (
            <Col className="w-100 ml-3 my-auto text-primary">
              <img
                src={Reaper}
                width={32}
                alt="Reap"
                className="mr-2"
                title="Reap"
                onClick={() => devModeUnlocked && setDevModal(true)}
              />
              <span className={devModeUnlocked ? "text-secondary" : ""}>
                {VERSION}-{BUILD_STAMP}
              </span>
            </Col>
          )}
          <Col>
            <Button
              as={Link}
              to="/terms"
              variant="link"
              className="float-right mr-0"
            >
              Terms of Service
            </Button>
          </Col>
        </Row>
      </footer>
      <DeveloperMenu show={devModeUnlocked && devModal} setShow={setDevModal} />
    </>
  );
};

export default Footer;
