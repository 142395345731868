import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import routes from "../Routing/Routes";
import Routes, { RouteContext } from "../Routing/Routing";
import { UserContext } from "../Sources/UserSource";
import Footer from "./Footer";
import Loader from "./Loader";

const RouteContainer = ({ location }: RouteComponentProps) => {
  const routeContext = useContext(RouteContext);
  const userContext = useContext(UserContext);

  const [navLength, setNavLength] = useState(0);
  const newNavLength = routeContext.currentRoutes.length;
  const [navDirection, setNavDirection] = useState(true);

  useEffect(() => {
    setNavDirection(newNavLength > navLength);
    setNavLength(newNavLength);
    // eslint-disable-next-line
  }, [routeContext.currentRoutes]);

  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.key}
        timeout={{ enter: 200, exit: 200 }}
        classNames={navDirection ? "fade" : "unfade"}
        in
      >
        <div>
          <Container className="py-4 min-vh-100 px-xs-0" as="main">
            <Loader loaded={!(routeContext.loading || userContext.loading)}>
              <Switch
                children={Routes(routes)}
                location={location}
                key={location.key}
              />
            </Loader>
          </Container>
          <Footer />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(RouteContainer);
