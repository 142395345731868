import React from "react";
import CriticalError from "../Views/CriticalError";

export class ErrorBoundary extends React.Component<{}, { error: any }> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <CriticalError
          stage={this.state.error.stage ?? "Generic"}
          err={this.state.error}
        />
      );
    } else {
      return this.props.children;
    }
  }
}
