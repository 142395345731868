import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { RouteContext } from "../../Routing/Routing";
import { useMutation, useQuery } from "@apollo/client";
import ManageFeatureComp from "../../Components/Forms/ManageFeatureForm";
import {
  DeleteHomeFeature,
  GetOrganization,
  UpdateHomeFeature,
} from "../../Queries";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";
import { IHomeFeatureForm } from "../../Interfaces";
import {
  DeleteHomeFeature as DeleteHomeFeatureData,
  DeleteHomeFeatureVariables,
} from "../../types/DeleteHomeFeature";
import Loader from "../../Components/Loader";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";
import {
  UpdateHomeFeature as UpdateHomeFeatureData,
  UpdateHomeFeatureVariables,
} from "../../types/UpdateHomeFeature";

const ManageFeature = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  let { fid } = useParams<{ fid: string }>();
  //Get organization data
  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  // set organization id
  let type_id =
    data?.organization?.features.find(
      (featureid) => featureid.id === (fid as string)
    ) ?? null;

  //call to create home type features
  const [updateHomeFeatures] = useMutation<
    UpdateHomeFeatureData,
    UpdateHomeFeatureVariables
  >(UpdateHomeFeature);

  //call to delete the feature
  const [deleteHomeFeature] = useMutation<
    DeleteHomeFeatureData,
    DeleteHomeFeatureVariables
  >(DeleteHomeFeature);

  async function handleDelete() {
    const variables: DeleteHomeFeatureVariables = {
      id: type_id?.id as string,
    };
    const { data } = await deleteHomeFeature({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Feature successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  //submit call to send data
  async function submitForm({ name, description }: IHomeFeatureForm) {
    const variables = {
      input: {
        id: type_id?.id as string,
        name,
        description,
      },
    };

    const { data } = await updateHomeFeatures({ variables });

    //if sucess then show message
    if (data?.updateFeature) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Feature successfully updated!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Edit Feature</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!type_id?.id}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            organization: "",
            name: type_id?.name ?? "",
            description: "",
          }}
          onSubmit={submitForm}
          validationSchema={ManageCompSchema}
        >
          <ManageFeatureComp />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageFeature;
