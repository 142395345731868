import React from "react";
import ReactDOM from "react-dom";
import Bootstrap from "./Bootstrap";
import "./Styles/bootstrap.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://a97cff0af8964efa942f26f15b75f3b9@o507639.ingest.sentry.io/5599031",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById("root")
);
