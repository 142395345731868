import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateHomeFoundEntry as CreateHomeFoundEntryQuery,
  CreateHomeFoundEntryVariables,
} from "../../types/CreateHomeFoundEntry";
import { GetHome, CreateHomeFoundEntry } from "../../Queries";
import FoundHomeComp from "../../Components/Forms/FoundHomeForm";
import FoundHomeSchema from "../../Validators/FoundHome";
import { GetHome as GetHomeQuery, GetHomeVariables } from "../../types/GetHome";

const FoundHome = () => {
  const { addToast } = useContext(RouteContext);
  //const { country, state } = (user! ?? {}).organization!;
  const [createHomeFoundEntry] = useMutation<
    CreateHomeFoundEntryQuery,
    CreateHomeFoundEntryVariables
  >(CreateHomeFoundEntry);

  const history = useHistory();
  const { bid } = useParams<{ bid: string }>();

  const { data } = useQuery<GetHomeQuery, GetHomeVariables>(GetHome, {
    variables: {
      id: bid,
    },
  });

  //submit call to send data
  async function submitForm({
    found_location: { address_1, address_2, city, state, postal_code, country },
  }: any) {
    const variables = {
      input: {
        id: +bid,
        found_home: {
          create: {
            address_1,
            address_2,
            city,
            state,
            postal_code,
            country,
          },
        },
      },
    };

    const { data } = await createHomeFoundEntry({ variables });

    //if sucess then show message
    if (data?.updateHome) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Buyer successfully updated!",
      });
      history.push("/homes");
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>New Buyer</h1>
          <p>Fill in all of the fields below.</p>
        </Col>
      </Row>
      <Formik
        initialValues={{
          found_location: {
            address_1: data?.home?.found_home?.address_1 ?? "",
            address_2: data?.home?.found_home?.address_2 ?? "",
            city: data?.home?.found_home?.city ?? "",
            state: data?.home?.found_home?.state ?? "",
            postal_code: data?.home?.found_home?.postal_code ?? "",
            country: data?.home?.found_home?.country ?? null,
          },
        }}
        onSubmit={submitForm}
        validationSchema={FoundHomeSchema}
      >
        <FoundHomeComp />
      </Formik>
    </Container>
  );
};

export default FoundHome;
