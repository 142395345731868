import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../Sources/UserSource";

const Logout = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (userContext != null && history != null)
      userContext.logOut().then(() => history.push("/login"));
  }, [userContext, history]);

  return <h2>Logging out...</h2>;
};

export default Logout;
