import { useField } from "formik";
import React, { DetailedHTMLProps, HTMLProps } from "react";
import {
  Col,
  Form,
  FormControlProps,
  InputGroup,
  ToggleButtonGroupProps,
} from "react-bootstrap";
import FormTabRadio, { IOption } from "./FormTabRadio";

const FormField = (({ label, column, append, prepend, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const groupProps = column
    ? { as: Col, className: column === true ? "" : `col-${column}`, style: {} }
    : {};

  if (props.type === "tabs") {
    groupProps["style"] = {
      overflowX: "auto",
      overflowY: "visible",
      padding: "5px",
    };
  }

  if (props.type === "switch") {
  }

  return (
    <Form.Group
      controlId={props.name}
      {...groupProps}
      className={`${groupProps.className} input-field`}
    >
      {props.type === "tabs" ? (
        <>
          {label !== null && <Form.Label>{label}</Form.Label>}
          <FormTabRadio
            {...field}
            {...props}
            onChange={(value) => helpers.setValue(value)}
            type="radio"
            // isInvalid={!!(meta.touched && meta.error)}
          />
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      ) : props.type === "checkbox" || props.type === "switch" ? (
        <Form.Check
          {...field}
          {...(props as any)}
          id={props.name}
          checked={field.value}
          isInvalid={!!(meta.touched && meta.error)}
          label={label}
        />
      ) : (
        <InputGroup>
          {prepend && (
            <InputGroup.Prepend>
              <InputGroup.Text>{prepend}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            {...field}
            {...props}
            isInvalid={!!(meta.touched && meta.error)}
          />
          {append && (
            <InputGroup.Append>
              <InputGroup.Text>{append}</InputGroup.Text>
            </InputGroup.Append>
          )}
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </InputGroup>
      )}
      {label && !(props.type === "checkbox" || props.type === "switch") && (
        <Form.Label className="text-muted">{label}</Form.Label>
      )}
      {/* {label && <Form.Label>{label}</Form.Label>} */}
    </Form.Group>
  );
}) as React.FC<
  FormControlProps &
    (
      | ({ type: "tabs" } & ToggleButtonGroupProps<string | number>)
      | DetailedHTMLProps<HTMLProps<HTMLInputElement>, HTMLInputElement>
    ) & {
      name: string;
      label?: string;
      column?: boolean | number | string;
      options?: IOption[];
      prepend?: React.ReactNode;
      append?: React.ReactNode;
    }
>;

export default FormField;
