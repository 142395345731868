import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import EditdLocationComp from "../../Components/Forms/EditLocationForm";
import { UpdateLocation, GetLocations, DeleteLocation } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  UpdateLocation as UpdateLocationQuery,
  UpdateLocationVariables,
} from "../../types/UpdateLocation";
import {
  DeleteLocation as DeleteLocationQuery,
  DeleteLocationVariables,
} from "../../types/DeleteLocation";
import { GetLocations as GetLocationsQuery } from "../../types/GetLocations";
import Loader from "../../Components/Loader";
import CityLocationSchema from "../../Validators/CityLocations";

const EditLocation = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);
  let { lid } = useParams<{ lid: string }>();

  const { data, loading } = useQuery<GetLocationsQuery>(GetLocations, {
    fetchPolicy: "cache-and-network",
  });
  const [updateLocation] = useMutation<
    UpdateLocationQuery,
    UpdateLocationVariables
  >(UpdateLocation);
  const [deleteLocation] = useMutation<
    DeleteLocationQuery,
    DeleteLocationVariables
  >(DeleteLocation);

  let type_id =
    data?.citylocation?.find((location) => location.id === (lid as string)) ??
    null;

  async function handleDelete() {
    const variables: DeleteLocationVariables = {
      id: type_id?.id as string,
    };
    const { data } = await deleteLocation({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Location successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }
  //submit call to send data
  async function submitForm({ name }: any) {
    const variables = {
      input: {
        id: type_id?.id as string,
        location: name,
      },
    };

    const { data } = await updateLocation({ variables });

    //if sucess then show message
    if (data?.updateCityLocation) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Location successfully updated!",
      });
      history.push(`/manage/locations/${data?.updateCityLocation.id}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Locations</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!type_id?.id}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            name: type_id?.location ?? "",
          }}
          onSubmit={submitForm}
          validationSchema={CityLocationSchema}
        >
          <EditdLocationComp />
        </Formik>
      </Loader>
    </Container>
  );
};

export default EditLocation;
