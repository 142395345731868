import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ManageQualityComp from "../../Components/Forms/ManageQualityForm";
import { IHomeQualityForm } from "../../Interfaces";
import {
  DeleteHomeQuality,
  GetOrganization,
  UpdateHomeQuality,
} from "../../Queries";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";
import { RouteContext } from "../../Routing/Routing";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../Components/Loader";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";
import {
  DeleteHomeQuality as DeleteHomeQualityData,
  DeleteHomeQualityVariables,
} from "../../types/DeleteHomeQuality";
import {
  UpdateHomeQuality as UpdateHomeQualityData,
  UpdateHomeQualityVariables,
} from "../../types/UpdateHomeQuality";

const ManageQuality = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  let { qid } = useParams<{ qid: string }>();

  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  let type_id =
    data?.organization?.homeQualities.find(
      (homequalityid) => homequalityid.id === (qid as string)
    ) ?? null;

  //call to update home type features
  const [updateHomeQuality] = useMutation<
    UpdateHomeQualityData,
    UpdateHomeQualityVariables
  >(UpdateHomeQuality);

  const [deleteHomeQuality] = useMutation<
    DeleteHomeQualityData,
    DeleteHomeQualityVariables
  >(DeleteHomeQuality);

  async function handleDelete() {
    const variables: DeleteHomeQualityVariables = {
      id: type_id?.id as string,
    };
    const { data } = await deleteHomeQuality({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Quality successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }
  //submit call to send data
  async function submitForm({
    name,
    description,
    positivity,
  }: IHomeQualityForm) {
    const variables = {
      input: {
        id: type_id?.id as string,
        name,
        description,
        positivity: 10,
      },
    };

    const { data } = await updateHomeQuality({ variables });

    //if sucess then show message
    if (data?.updateHomeQuality) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Quality successfully updated!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Edit Quality</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!type_id?.id}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>

      <Loader loaded={!loading && data != null}>
        <Formik
          initialValues={{
            organization: "",
            name: type_id?.name ?? "",
            description: "",
            positivity: (type_id?.positivity ?? 1).toString(),
          }}
          onSubmit={submitForm}
          validationSchema={ManageCompSchema}
        >
          <ManageQualityComp />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageQuality;
