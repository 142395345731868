import * as yup from "yup";
import "yup-phone";

const CreateUserSchema = yup.object().shape({
  first_name: yup.string().required("Please enter first name "),
  last_name: yup.string().required("Please enter last name "),
  phone_number: yup.string().phone().label("Phone number"),
  email: yup.string().required("Please enter email"),
  password: yup
    .string()
    .min(6, "Password is too short - should be 6 chars minimum.")
    .required("Please enter the password"),
  password_confirm: yup.string().when("password", {
    is: (value: any) => !!value,
    then: yup
      .string()
      .required("Must confirm password")
      .oneOf([yup.ref("password")], "Passwords must match"),
  }),
  organization: yup.string().nullable(),
});

export default CreateUserSchema;
