import { Redirect } from "react-router-dom";
import AddLocation from "../Views/Admin/AddLocation";
import EditFeature from "../Views/Admin/EditFeature";
import EditHomeType from "../Views/Admin/EditHomeType";
import EditLocation from "../Views/Admin/EditLocation";
import EditQuality from "../Views/Admin/EditQuality";
import ListOrganization from "../Views/Admin/ListOrganization";
import ManageDirectory from "../Views/Admin/ManageDirectory";
import ManageDirectoryCategory from "../Views/Admin/ManageDirectoryCategory";
import ManageFeature from "../Views/Admin/ManageFeature";
import ManageHomeType from "../Views/Admin/ManageHomeType";
import ManageOrganization from "../Views/Admin/ManageOrganization";
import ManageQuality from "../Views/Admin/ManageQuality";
import ManageUser from "../Views/Admin/ManageUser";
import NewDirectory from "../Views/Admin/NewDirectory";
import NewDirectoryCategory from "../Views/Admin/NewDirectoryCategory";
import NewOrganization from "../Views/Admin/NewOrganization";
import NewUser from "../Views/Admin/NewUser";
import AccountSettings from "../Views/Auth/AccountSettings";
import ForgotPassword from "../Views/Auth/ForgotPassword";
import HomeScreen from "../Views/Auth/HomeScreen";
import Login from "../Views/Auth/Login";
import Logout from "../Views/Auth/Logout";
import TermsOfService from "../Views/Auth/TermsOfService";
import NotFound from "../Views/NotFound";
import Directory from "../Views/Realtor/Directory";
import FoundHome from "../Views/Realtor/FoundHome";
import ListHome from "../Views/Realtor/ListHome";
import ManageHome from "../Views/Realtor/ManageHome";
import NewHome from "../Views/Realtor/NewHome";
import Videos from "../Views/Realtor/Videos";
import { IRoute } from "./IRoute";

export default [
  {
    Title: "Home",
    Route: "/",
    Allowed: ["realtor", "admin"],
    RedirectUnauthorized: "/login",
    View: HomeScreen,
    Exact: true,
    Children: [
      {
        Title: "Log in",
        Route: "/login",
        Allowed: ["all"],
        RedirectUnauthorized: "/",
        View: Login,
        Children: [
          {
            Title: "Recover Account",
            Route: "/forgot",
            Allowed: ["guest"],
            RedirectUnauthorized: "/",
            View: ForgotPassword,
          },
        ],
      },
      {
        Title: "Log out",
        Route: "/logout",
        Allowed: ["user"],
        RedirectUnauthorized: "/login",
        View: Logout,
      },
      {
        Title: "Terms of Service",
        Route: "/terms",
        Allowed: ["all"],
        View: TermsOfService,
      },
      {
        Title: "Account Settings",
        Route: "/user",
        Allowed: ["user"],
        RedirectUnauthorized: "/login",
        View: AccountSettings,
      },
      {
        Title: "Videos",
        Route: "/videos",
        Allowed: ["client"],
        RedirectUnauthorized: "/login",
        View: Videos,
      },
      {
        Title: "Directory",
        Route: "/directory",
        Allowed: ["realtor", "admin"],
        RedirectUnauthorized: "/",
        View: Directory,
      },
      {
        Title: "Home List",
        Route: "/homes",
        Allowed: ["realtor", "admin"],
        RedirectUnauthorized: "/",
        View: ListHome,
        Children: [
          {
            Title: "New Buyer",
            Route: "/manage/homes/new",
            Allowed: ["realtor", "admin"],
            RedirectUnauthorized: "/",
            View: NewHome,
            //View: test
          },
          {
            Title: "Update Buyer",
            Route: "/manage/homes/:bid",
            Allowed: ["realtor", "admin"],
            RedirectUnauthorized: "/",
            View: ManageHome,
          },
          {
            Title: "Buyer Found Home",
            Route: "/homes/found/:bid",
            Allowed: ["realtor", "admin"],
            RedirectUnauthorized: "/",
            View: FoundHome,
          },
        ],
      },
      {
        Title: "Administration",
        Route: "/manage",
        Allowed: ["god"],
        RedirectUnauthorized: "/",
        View: () => <Redirect to="/manage/organizations" />,
        Children: [
          {
            Title: "New User",
            Route: "/manage/users/new",
            Allowed: ["admin", "god"],
            RedirectUnauthorized: "/",
            View: NewUser,
          },
          {
            Title: "Update User",
            Route: "/manage/users/:uid",
            Allowed: ["admin", "god"],
            RedirectUnauthorized: "/",
            View: ManageUser,
          },
          {
            Title: "Organizations",
            Route: "/manage/organizations",
            Allowed: ["god"],
            RedirectUnauthorized: "/",
            View: ListOrganization,
            Children: [
              {
                Title: "New Organization",
                Route: "/manage/organizations/new",
                Allowed: ["god"],
                RedirectUnauthorized: "/",
                View: NewOrganization,
              },
              {
                Title: "Manage Organization",
                Route: "/manage/organizations/:oid",
                Allowed: ["admin", "god"],
                RedirectUnauthorized: "/",
                View: ManageOrganization,
                Children: [
                  {
                    Title: "New Home Type",
                    Route: "/manage/hometypes/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: ManageHomeType,
                  },
                  {
                    Title: "Update Home Type",
                    Route: "/manage/hometypes/:htid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: EditHomeType,
                  },
                  {
                    Title: "New Feature",
                    Route: "/manage/features/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: ManageFeature,
                  },
                  {
                    Title: "Update Feature",
                    Route: "/manage/features/:fid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: EditFeature,
                  },
                  {
                    Title: "New Contact",
                    Route: "/manage/directories/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: NewDirectory,
                  },
                  {
                    Title: "Update Contact",
                    Route: "/manage/directories/:cid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: ManageDirectory,
                  },
                  {
                    Title: "New Directory Category",
                    Route: "/manage/directorycategories/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: NewDirectoryCategory,
                  },
                  {
                    Title: "Update Directory Category",
                    Route: "/manage/directorycategories/:dcid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: ManageDirectoryCategory,
                  },
                  {
                    Title: "New Quality",
                    Route: "/manage/qualities/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: ManageQuality,
                  },
                  {
                    Title: "Update Quality",
                    Route: "/manage/qualities/:qid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: EditQuality,
                  },
                  {
                    Title: "New Location",
                    Route: "/manage/locations/new",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: AddLocation,
                  },
                  {
                    Title: "Update Location",
                    Route: "/manage/locations/:lid",
                    Allowed: ["admin", "god"],
                    RedirectUnauthorized: "/",
                    View: EditLocation,
                  },
                ],
              },
            ],
          },
        ],
      },
      /// 404 ROUTE MUST ALWAYS BE AT THE BOTTOM
      {
        Route: "*",
        Title: "404 Not Found",
        Allowed: ["user"],
        RedirectUnauthorized: "/login",
        View: NotFound,
      },
    ],
  },
] as IRoute[];
