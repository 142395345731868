import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ManageQualityComp from "../../Components/Forms/ManageQualityForm";
import { IHomeQualityForm } from "../../Interfaces";
import { CreateHomeQuality } from "../../Queries";
import { RouteContext } from "../../Routing/Routing";
import {
  CreateHomeQuality as CreateHomeQualityData,
  CreateHomeQualityVariables,
} from "../../types/CreateHomeQuality";
import ManageCompSchema from "../../Validators/ManageComponentsSchema";

const ManageQuality = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  //call to create home type features
  const [createHomeQuality] = useMutation<
    CreateHomeQualityData,
    CreateHomeQualityVariables
  >(CreateHomeQuality);

  //submit call to send data
  async function submitForm({
    name,
    description,
    positivity,
  }: IHomeQualityForm) {
    const variables = {
      input: {
        organization: { connect: oid },
        name,
        description,
        positivity: 10,
      },
    };

    const { data } = await createHomeQuality({ variables });

    //if sucess then show message
    if (data?.createHomeQuality) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Home Quality successfully created!",
      });
      history.push(`/manage/qualities/${data?.createHomeQuality.id}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Quality</h1>
        </Col>
      </Row>
      <Formik
        initialValues={{
          organization: "",
          name: "",
          description: "",
          positivity: "1",
        }}
        onSubmit={submitForm}
        validationSchema={ManageCompSchema}
      >
        <ManageQualityComp />
      </Formik>
    </Container>
  );
};

export default ManageQuality;
