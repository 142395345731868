import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { API_BASE } from "./Sources/BuildMetadata";
const possibleTypes = require("./Sources/possibleTypes.json");

function getCookieValue(name: string): string {
  var b = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return (b ? b.pop() : "") ?? "";
}

// Sets up the GraphQL link to the backend
const ApolloSetup = function (
  handleError: (part: string, err: any, critical: boolean) => void
) {
  // Create the HTTP link
  const httpLink = createHttpLink({
    uri: `${API_BASE}/api/graphql`,
    credentials: "include",
    useGETForQueries: true,
  });
  // Handle network/GraphQL errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL(Query)]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      handleError("GraphQL(Query)", graphQLErrors, false);
    }

    if (networkError) {
      console.log(`[GraphQL(Network)]: ${networkError}`);
      handleError("GraphQL(Network)", networkError, true);
    }
  });

  // Add the CSRF token to requests
  const xsrfLink = setContext((_, { headers }) => {
    const token = getCookieValue("XSRF-TOKEN");
    return {
      headers: {
        ...headers,
        "X-XSRF-TOKEN": token ? `${decodeURIComponent(token)}` : "",
      },
    };
  });

  // Finally, create the entire link
  const link = from([errorLink, xsrfLink, httpLink]);

  // ... and the client itself
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({ possibleTypes }),
  });
  return client;
};

export default ApolloSetup;
