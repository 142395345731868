import React, { useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IRoute } from "../Routing/IRoute";
import { RouteContext, SubstituteValues } from "../Routing/Routing";

const Breadcrumbs = () => {
  const { currentRoutes, routeData } = useContext(RouteContext);
  const route = currentRoutes[currentRoutes.length - 1] || {};

  return (
    <Breadcrumb
      className="align-middle"
      listProps={{ className: "bg-light rounded-0" }}
    >
      {currentRoutes
        .slice(0, currentRoutes.length - 1)
        .map((route: IRoute, i) => (
          <Breadcrumb.Item as="span" key={route.Route}>
            <Link to={SubstituteValues(route.Route, routeData)}>
              {route.Title}
            </Link>
          </Breadcrumb.Item>
        ))}
      <Breadcrumb.Item key={route.Route} active>
        {route.Title}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
