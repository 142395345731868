import { NetworkStatus, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "../../Components/Form";
import HomeFeature from "../../Components/HomeFeature";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import { GetHomes } from "../../Queries";
import { DISABLED_FEATURES } from "../../Sources/BuildMetadata";
import { UserContext } from "../../Sources/UserSource";
import {
  GetHomes as GetHomesQuery,
  GetHomesVariables,
} from "../../types/GetHomes";
import { HomeFeatureSearch } from "../../types/globalTypes";
import { useMediaQuery } from "react-responsive";
import { HomesOrderByColumn, SortOrder } from "../../types/globalTypes";
import AutoComplete from "../../Components/Forms/AutoComplete";
import { CSVLink } from "react-csv";

function notEmptyLike(
  input: string | undefined,
  add: string = ""
): string | undefined {
  return (input?.trim?.()?.length ?? 0) > 0 ? input!.trim() + add : undefined;
}

const ListHome = () => {
  const { user } = useContext(UserContext);
  const { features, homeQualities, homeTypes } = user?.organization ?? {};
  const [foundHome, setFoundHome] = useState(false);
  const { data, refetch, loading, networkStatus } = useQuery<
    GetHomesQuery,
    GetHomesVariables
  >(GetHomes, {
    variables: {
      first: 10,
      organization_id: user?.organization?.id ?? "1",
      orderBy: [
        {
          field: HomesOrderByColumn.MAX_PRICE,
          order: SortOrder.ASC,
        },
      ],
      found_home: foundHome,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const priceInterval = user?.organization?.settings
    ?.slice(0, 1)
    .map((val) => val.value);

  const updateFilters = async (data: any) => {
    await refetch({
      first_name: notEmptyLike(data.first_name, "%"),
      last_name: notEmptyLike(data.last_name, "%"),
      locations: (data.locations ?? []).map((val: any) => {
        return {
          id: val.value,
          location: val.label,
        };
      }),
      features: Object.keys(data.features).reduce((acc, feature_id) => {
        return [
          ...acc,
          ...(data.features[feature_id] !== ""
            ? [
                {
                  feature_id,
                  value: data.features[feature_id],
                },
              ]
            : []),
        ];
      }, [] as HomeFeatureSearch[]),
      type: notEmptyLike(data.home_type),
      max_price: data.max_price ? data.max_price : undefined,
      min_price: data.min_price ? data.min_price : undefined,
      min_baths: data.min_baths ? data.min_baths : undefined,
      min_beds: data.min_beds ? data.min_beds : undefined,
      oncollaboption:
        data.collaboption === "Y"
          ? true
          : data.collaboption === "N"
          ? false
          : undefined,
      quality: notEmptyLike(data.quality) as any,
    });
  };

  const csvData =
    data?.organization?.homes?.data.map((val) => ({
      Name: val.first_name + "" + val.last_name,
      Locations: val.locations?.map((loc) => loc?.location),
      MaximumPrice: val.max_price,
      DateAdded: val.created_at,
      Number_of_Beds: val.min_beds,
      Number_of_Baths: val.min_baths,
      Notes: val.additional_notes,
    })) ?? [];

  const [priceSortOrder, setPriceOrder] = useState("");
  const [dateSortOrder, setDateOrder] = useState("");
  const paginationInfo = data?.organization?.homes?.paginatorInfo;
  const [show, setShow] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleFoundHome = (key: string) => {
    if (key === "found_home") {
      setFoundHome(true);
    } else {
      setFoundHome(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, [show]);

  const requestSort = (key: string) => {
    if (key === "MAX_PRICE" && priceSortOrder === "ASC") {
      setPriceOrder("DESC");
      refetch({
        orderBy: [
          {
            field: HomesOrderByColumn.MAX_PRICE,
            order: SortOrder.ASC,
          },
        ],
      });
    } else if (key === "MAX_PRICE") {
      setPriceOrder("ASC");

      refetch({
        orderBy: [
          {
            field: HomesOrderByColumn.MAX_PRICE,
            order: SortOrder.DESC,
          },
        ],
      });
    }
    if (key === "CREATED_AT" && dateSortOrder === "ASC") {
      setDateOrder("DESC");

      refetch({
        orderBy: [
          {
            field: HomesOrderByColumn.CREATED_AT,
            order: SortOrder.ASC,
          },
        ],
      });
    } else if (key === "CREATED_AT") {
      setDateOrder("ASC");
      refetch({
        orderBy: [
          {
            field: HomesOrderByColumn.CREATED_AT,
            order: SortOrder.DESC,
          },
        ],
      });
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Home List</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion>
            <Card bg="secondary" text="dark">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Search
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Formik
                    onSubmit={updateFilters}
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      features: features?.reduce(
                        (acc, feature) => ({
                          ...acc,
                          [feature.id]: "",
                        }),
                        {} as any
                      ),
                      quality: "",
                    }}
                  >
                    <Form>
                      <Button type="submit" variant="secondary">
                        Update filter
                      </Button>
                      <Form.Row>
                        <Form.Field
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          column
                        />
                        <Form.Field
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          column
                        />
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <p> Location</p>
                        </Col>
                      </Form.Row>
                      <AutoComplete name="locations" />
                      <Form.Row>
                        <Form.Field
                          name="min_price"
                          type="number"
                          step={priceInterval?.toString()}
                          min="0"
                          label="Minimum Price (optional)"
                          column
                        />
                        <Form.Field
                          name="max_price"
                          type="number"
                          step={priceInterval?.toString()}
                          min="0"
                          label="Maximum Price (required)"
                          column
                        />
                      </Form.Row>
                      <Form.Row className="col-6">
                        <Form.Field
                          default
                          name="collaboption"
                          type="radio"
                          value={"NA"}
                          label="Any (Collab Center Option)"
                          column
                          custom
                        />
                        <Form.Field
                          name="collaboption"
                          type="radio"
                          value={"Y"}
                          label="Yes (Collab Center Option)"
                          column
                          custom
                        />

                        <Form.Field
                          name="collaboption"
                          type="radio"
                          value={"N"}
                          label="No (Collab Center Option)"
                          column
                          custom
                        />
                      </Form.Row>
                      {!DISABLED_FEATURES.includes("HOME_ADVANCED_SEARCH") && (
                        <Row>
                          <Col>
                            <Accordion>
                              <Card bg="secondary" text="dark">
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="1"
                                  >
                                    Advanced
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                    <Form.Row>
                                      <Form.Field
                                        name="min_beds"
                                        type="number"
                                        min="0"
                                        label="Minimum Bedrooms"
                                        column
                                      />
                                      <Form.Field
                                        name="min_baths"
                                        type="number"
                                        min="0"
                                        label="Minimum Bathrooms"
                                        column
                                      />
                                      <Form.Select
                                        name="type"
                                        type="select"
                                        placeholder="Select..."
                                        label="House Type"
                                        column
                                        options={homeTypes
                                          ?.map((type) => ({
                                            value: type.id,
                                            label: type.name,
                                          }))
                                          .concat({
                                            label: "All",
                                            value: "",
                                          })}
                                        disabled={homeTypes == null}
                                        loading={homeTypes == null}
                                      />
                                    </Form.Row>
                                    <Form.Row>
                                      {isTabletOrMobile ? (
                                        <Loader loaded={homeQualities != null}>
                                          <Form.Field
                                            style={{ flexDirection: "column" }}
                                            name="quality"
                                            type="tabs"
                                            column
                                            options={(
                                              homeQualities
                                                ?.slice()
                                                ?.sort(
                                                  (a, b) =>
                                                    a.positivity - b.positivity
                                                )
                                                ?.map((quality) => ({
                                                  label: quality.name,
                                                  value: quality.id,
                                                })) ?? []
                                            ).concat({
                                              label: "All",
                                              value: "",
                                            })}
                                          />
                                        </Loader>
                                      ) : (
                                        <Loader loaded={homeQualities != null}>
                                          <Form.Field
                                            style={{ flexDirection: "row" }}
                                            name="quality"
                                            type="tabs"
                                            column
                                            options={(
                                              homeQualities
                                                ?.slice()
                                                ?.sort(
                                                  (a, b) =>
                                                    a.positivity - b.positivity
                                                )
                                                ?.map((quality) => ({
                                                  label: quality.name,
                                                  value: quality.id,
                                                })) ?? []
                                            ).concat({
                                              label: "All",
                                              value: "",
                                            })}
                                          />
                                        </Loader>
                                      )}
                                    </Form.Row>
                                    <Row className="mt-4">
                                      <Col>
                                        <h2>Features</h2>
                                      </Col>
                                    </Row>
                                    <Loader loaded={features != null}>
                                      {features?.map((feature) => (
                                        <HomeFeature
                                          key={feature.id}
                                          name={`features.${feature.id}`}
                                          label={feature.name}
                                          removable
                                        />
                                      ))}
                                    </Loader>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </Formik>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Pagination paginatorInfo={paginationInfo} refetch={refetch} />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Loader
            loaded={
              !(
                loading ||
                networkStatus === NetworkStatus.refetch ||
                data == null
              ) && show
            }
          >
            <div className="col-12">
              <Formik
                onSubmit={(data) => handleFoundHome(data.foundhome)}
                initialValues={{ foundhome: "test" }}
              >
                <Form className="row">
                  <div className="col-4">
                    <Form.Select
                      name="foundhome"
                      options={[
                        {
                          label: "Buyers with No Home",
                          value: "no_home_found",
                        },
                        {
                          label: "Buyers Found Home",
                          value: "found_home",
                        },
                      ]}
                    />
                  </div>

                  <Button
                    className="mt-4"
                    type="submit"
                    variant="primary"
                    style={{
                      height: "10px",
                      lineHeight: "0.3",
                      flexDirection: "row",
                    }}
                  >
                    submit
                  </Button>
                </Form>
              </Formik>
            </div>

            <div className="table-responsive">
              <Table hover className="table table-striped" responsive="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>
                      <Button
                        type="button"
                        variant="link"
                        style={{ textTransform: "capitalize", padding: "0" }}
                        onClick={() => requestSort("MAX_PRICE")}
                      >
                        Maximum Price
                        {priceSortOrder === "ASC" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                            />
                          </svg>
                        )}
                      </Button>
                    </th>
                    <th>
                      <Button
                        type="button"
                        variant="link"
                        style={{ textTransform: "capitalize", padding: "0" }}
                        onClick={() => requestSort("CREATED_AT")}
                      >
                        Date Added
                        {dateSortOrder === "ASC" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                            />
                          </svg>
                        )}
                      </Button>
                    </th>
                    <th>Beds</th>
                    <th>Bathrooms</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {(data?.organization?.homes?.data ?? []).map((buyer) => (
                    <tr key={buyer.id}>
                      <td>
                        <Link to={`/manage/homes/${buyer.id}`} key={buyer.id}>
                          {buyer.first_name} {buyer.last_name}
                        </Link>
                      </td>
                      <td>
                        {`${
                          buyer.locations?.[0]?.location ?? "Not Applicable"
                        } ${
                          (buyer?.locations ?? "").length > 1
                            ? `and ${(buyer?.locations ?? "").length - 1} more`
                            : ""
                        }` ?? ""}
                      </td>
                      <td>{buyer.max_price}</td>
                      <td>{buyer.created_at}</td>
                      <td>{buyer.min_beds}</td>
                      <td>{buyer.min_baths}</td>
                      <td>{buyer.additional_notes ?? "(none)"}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={7}></td>
                  </tr>
                  <tr>
                    <td colSpan={7} className="p-0">
                      <CSVLink
                        type="Button"
                        className="btn btn-primary"
                        data={csvData}
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Export Results{" "}
                      </CSVLink>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Loader>
        </Col>
      </Row>
    </Container>
  );
};

export default ListHome;
