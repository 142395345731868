import React, { useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Axios from "../../AxiosInstance";
import { API_BASE } from "../../Sources/BuildMetadata";
import Form from "../Form";
import { useFormikContext } from "formik";
import LocationForm from "../LocationForm";
import { IGeneralOrganizationForm } from "../../Interfaces";
import Loader from "../Loader";

const GeneralOrganization = (() => {
  const { isSubmitting } = useFormikContext();
  const {
    values,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
  } = useFormikContext<IGeneralOrganizationForm>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [disable, setDisable] = useState(false);
  async function uploadFile(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = evt.currentTarget.files?.[0];
    if (file) {
      const data = new FormData();
      data.append("image", file);
      data.append("organization_id", "1");
      const logo_id = await Axios.post(`${API_BASE}/api/upload_logo`, data);
      setFieldTouched("logo_id", true);
      setFieldValue("logo_id", logo_id.data);
    }
  }

  return (
    <div>
      {disable ? (
        <div style={{ pointerEvents: "none", opacity: "0.4" }}>
          <Form className="mt-4">
            <Loader loaded={!isSubmitting} />
            <Form.Row>
              <Col
                as="label"
                htmlFor="upload_logo"
                className="col-auto mr-4 icon-upload"
              >
                <img
                  src={`${API_BASE}/${values?.logo_id ?? ""}`}
                  style={{
                    objectFit: "contain",
                    width: "100px",
                    height: "100px",
                  }}
                  className="mx-auto border rounded"
                  alt=""
                />
                <input
                  id="upload_logo"
                  type="file"
                  style={{ display: "none" }}
                  ref={fileRef}
                  onChange={uploadFile}
                />
              </Col>
              <Form.Field
                type="text"
                name="name"
                placeholder="Name"
                autoComplete="off"
                column
              />
            </Form.Row>
            <Form.Row>
              <Form.Field
                type="url"
                placeholder="Website"
                name="website_url"
                autoComplete="off"
                column
              />
              <Form.Field
                type="tel"
                placeholder="Phone Number"
                name="phone_number"
                autoComplete="off"
                column
              />
            </Form.Row>
            <LocationForm name="location" />
            <Row className="mt-4">
              <Col className="float-right">
                <Button
                  variant="success"
                  disabled={!isValid || !dirty}
                  type="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div>
          <Form className="mt-4">
            <Loader loaded={!isSubmitting} />
            <Form.Row>
              <Col
                as="label"
                htmlFor="upload_logo"
                className="col-auto mr-4 icon-upload"
              >
                <img
                  src={`${API_BASE}/${values?.logo_id ?? ""}`}
                  style={{
                    objectFit: "contain",
                    width: "100px",
                    height: "100px",
                  }}
                  className="mx-auto border rounded"
                  alt=""
                />
                <input
                  id="upload_logo"
                  type="file"
                  style={{ display: "none" }}
                  ref={fileRef}
                  onChange={uploadFile}
                />
              </Col>
              <Form.Field
                type="text"
                name="name"
                placeholder="Name"
                autoComplete="off"
                column
              />
            </Form.Row>
            <Form.Row>
              <Form.Field
                type="url"
                placeholder="Website"
                name="website_url"
                autoComplete="off"
                column
              />
              <Form.Field
                type="tel"
                placeholder="Phone Number"
                name="phone_number"
                autoComplete="off"
                column
              />
            </Form.Row>
            <LocationForm name="location" />
            <Row className="mt-4">
              <Col className="float-right">
                <Button
                  variant="success"
                  disabled={!isValid || !dirty}
                  type="submit"
                  onClick={() => setDisable(true)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}) as React.FC;

export default GeneralOrganization;
