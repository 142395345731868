import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Form from "../../Components/Form";
import { RouteContext } from "../../Routing/Routing";
import { BUILD_TIME, DISABLED_FEATURES } from "../../Sources/BuildMetadata";
import { UserContext } from "../../Sources/UserSource";

const DeveloperMenu = (({ show, setShow }) => {
  const userContext = useContext(UserContext);
  const routeContext = useContext(RouteContext);

  const [runModalData, setRunModalData] = useState({
    open: false,
    context: null as any | null,
    functionName: null as string | null,
  });

  function openRunModal(context: any, key: string) {
    setRunModalData({
      open: true,
      context: context,
      functionName: key,
    });
  }

  async function runModal({ args }: { args: string[] }) {
    setRunModalData({
      open: false,
      context: null,
      functionName: null,
    });

    const argsArray = args.map((val) => {
      try {
        return JSON.parse(val);
      } catch (e) {
        return val + "";
      }
    });

    let func = runModalData.context[runModalData.functionName!];
    try {
      const result = await func.apply(runModalData, argsArray);
      alert(JSON.stringify(result));
    } catch (e) {
      alert("Error while running: " + e.stack);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        variant="dark"
        className="max-vw-100"
      >
        <Modal.Header className="bg-dark text-light">
          Developer Mode
        </Modal.Header>
        <Modal.Body className="bg-dark text-light overflow-auto">
          <h4>Information</h4>
          <Table bordered variant="dark" className="w-auto">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Build date</td>
                <td>{new Date(BUILD_TIME).toString()}</td>
              </tr>
              <tr>
                <td>Disabled features</td>
                <td>{DISABLED_FEATURES.join(", ")}</td>
              </tr>
              <tr>
                <td>Environment</td>
                <td>{process.env["NODE_ENV"]}</td>
              </tr>
            </tbody>
          </Table>
          <h4>UserContext</h4>
          <Table bordered variant="dark" className="w-auto">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(userContext).map((key) => (
                <tr key={key}>
                  <td className="text-monospace">{key}</td>
                  <td>
                    {(userContext as any)[key]?.constructor?.name ??
                      typeof (userContext as any)[key]}
                  </td>
                  <td className="text-monospace">
                    <pre className="text-light">
                      {JSON.stringify(
                        (userContext as any)?.[key],
                        null,
                        "  "
                      ) || (userContext as any)[key]?.toString()}
                    </pre>
                  </td>
                  <td>
                    {typeof (userContext as any)[key] === "function" && (
                      <Button onClick={() => openRunModal(userContext, key)}>
                        Run
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
          <h4>RouteContext</h4>
          <Table bordered variant="dark">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(routeContext)
                .filter((key) => key !== "currentRoutes")
                .map((key) => (
                  <tr key={key}>
                    <td className="text-monospace">{key}</td>
                    <td>
                      {(routeContext as any)[key]?.constructor?.name ??
                        typeof (routeContext as any)[key]}
                    </td>
                    <td className="text-monospace">
                      <pre className="text-light">
                        {JSON.stringify(
                          (routeContext as any)?.[key],
                          null,
                          " "
                        ) || (routeContext as any)[key]?.toString()}
                      </pre>
                    </td>
                    <td>
                      {typeof (routeContext as any)[key] === "function" && (
                        <Button onClick={() => openRunModal(routeContext, key)}>
                          Run
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Modal
        show={runModalData.open}
        onHide={() =>
          setRunModalData({
            open: false,
            context: null,
            functionName: null,
          })
        }
        key={runModalData.functionName}
      >
        <Modal.Header className="bg-dark text-light">
          Run function: {runModalData.functionName}
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <Formik
            initialValues={{
              args: new Array(
                runModalData.context?.[runModalData.functionName ?? ""]
                  ?.length ?? 1
              ).fill(""),
            }}
            onSubmit={runModal}
          >
            <Form>
              <Form.Multi name="args" />
              <Button type="submit">Run</Button>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}) as React.FC<{ show: boolean; setShow: (v: boolean) => void }>;

export default DeveloperMenu;
