import { useQuery } from "@apollo/client";
import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Edit2, Plus } from "react-feather";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { GetOrganizations } from "../../Queries";
import {
  GetOrganizations as GetOrganizationsQuery,
  GetOrganizationsVariables,
} from "../../types/GetOrganizations";

const ListOrganization = () => {
  const { data, loading } = useQuery<
    GetOrganizationsQuery,
    GetOrganizationsVariables
  >(GetOrganizations, {
    fetchPolicy: "network-only",
  });

  return (
    <Container fluid>
      <Row>
        <Col className="w-100">
          <h1>Organization Management</h1>
        </Col>
        <Col>
          <Button
            variant="primary"
            as={Link}
            to="/manage/organizations/new"
            className="float-right"
          >
            <Plus /> Add
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Loader loaded={!loading}>
            <Table hover>
              <thead>
                <tr>
                  <th>Organization Name</th>
                  <th>Owner</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.organizations?.data?.map((org) => (
                  <tr key={org.id}>
                    <td>{org.name}</td>
                    <td>N/A</td>
                    <td>
                      <Link to={`/manage/organizations/${org.id}`}>
                        <Edit2 size={16} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Loader>
        </Col>
      </Row>
    </Container>
  );
};

export default ListOrganization;
