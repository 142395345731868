import React, { useContext } from "react";
import { Toast } from "react-bootstrap";
import { RouteContext } from "../Routing/Routing";

const ToastArea = () => {
  const { toasts, removeToast } = useContext(RouteContext);
  console.log(toasts);

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "relative",
        maxHeight: "100vh",
      }}
    >
      <div
        style={{
          position: "fixed",
          bottom: "1em",
          right: "1em",
        }}
      >
        {toasts.map((toast, i) => (
          <Toast
            key={i}
            show={toasts.includes(toast)}
            onClose={() => removeToast(toast)}
            autohide
            delay={3000}
          >
            <Toast.Header>{toast.header}</Toast.Header>
            <Toast.Body>{toast.body}</Toast.Body>
          </Toast>
        ))}
      </div>
    </div>
  );
};

export default ToastArea;
