import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { Container } from "react-bootstrap";
import { ILocationForm } from "../Interfaces";
import { GetCountryAndProvinces } from "../Queries";
import { GetCountryAndProvinces as GetCountryAndProvincesQuery } from "../types/GetCountryAndProvinces";
import Form from "./Form";

const LocationForm = (({ name }) => {
  const { data: locationData, loading } = useQuery<GetCountryAndProvincesQuery>(
    GetCountryAndProvinces
  );
  const { values } = useFormikContext<{ [i: string]: ILocationForm }>();

  const countries =
    useMemo(() => {
      return (
        locationData?.countries?.map?.((val) => ({
          label: val.name,
          value: val.value,
        })) ?? []
      );
    }, [locationData]) ?? [];

  const country = values[name]?.country;
  const provinces = useMemo(() => {
    const c = locationData?.countries?.find?.((v) => v.value === country);
    return (country as string) === ""
      ? []
      : c?.provinces?.map((prov) => ({
          label: prov.name,
          value: prov.abbreviation,
        })) ?? [
          {
            label: c?.name ?? "",
            value: c?.value ?? "",
          },
        ];
  }, [country, locationData]);

  return (
    <Container fluid className="px-0">
      <Form.Row>
        <Form.Field
          column
          type="text"
          placeholder="Address"
          name={`${name}.address_1`}
          autoComplete="off"
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          column
          type="text"
          placeholder="Address 2"
          name={`${name}.address_2`}
          autoComplete="off"
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          column
          type="text"
          placeholder="City"
          name={`${name}.city`}
          autoComplete="off"
        />
        <Form.Select
          column
          name={`${name}.state`}
          placeholder="Province/State"
          options={provinces}
          isDisabled={(country as string) === ""}
        />
        <Form.Field
          column
          type="text"
          placeholder="Postal/ZIP Code"
          name={`${name}.postal_code`}
          autoComplete="off"
        />
      </Form.Row>
      <Form.Row>
        <Form.Select
          column={4}
          disabled={loading}
          name={`${name}.country`}
          placeholder="Country"
          options={countries}
        />
      </Form.Row>
    </Container>
  );
}) as React.FC<{ name: string }>;

export default LocationForm;
