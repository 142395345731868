import LocationSchema from "./Location";
import * as yup from "yup";
import "yup-phone";

const ManageOrgSchema = yup.object().shape({
  location: LocationSchema,
  name: yup.string().required("Please enter organization name").max(100).min(0),
  website_url: yup.string().required("Please enter website url").min(0),
  phone_number: yup.string().phone().label("Phone Number"),
});

export default ManageOrgSchema;
