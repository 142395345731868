import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, useHistory } from "react-router-dom";
import { IToast } from "../Interfaces";
import { UserContext } from "../Sources/UserSource";
import { Role } from "../types/globalTypes";
import { IRoute } from "./IRoute";
import routes from "./Routes";

export const SubstituteValues = (url: string, params: any) =>
  url.replace(/:(\w+)\b/g, (match, key) => {
    return key in params ? params[key] : match;
  });

export const RouteContext = React.createContext({
  currentRoutes: [] as IRoute[],
  loading: true,
  routeData: {} as any,
  setCurrentRoute: (route: IRoute) => {},
  setLoading: (loading: boolean) => {},
  setRouteData: (data: any) => {},
  toasts: [] as IToast[],
  addToast: (toast: IToast) => {},
  removeToast: (toast: IToast) => {},
});

export const GetRoutePath = (
  route: IRoute,
  routeList: IRoute[] = routes
): IRoute[] | null => {
  for (const parent of routeList) {
    if (route === parent) {
      return [parent];
    } else if (parent.Children?.includes(route)) {
      return [parent, route];
    } else if (parent.Children) {
      const subList = GetRoutePath(route, parent.Children);
      if (subList) {
        return [parent, ...subList];
      }
    }
  }
  return null;
};

const RTWrap = (({ route }) => {
  const routeContext = useContext(RouteContext);
  const userContext = useContext(UserContext);
  const history = useHistory<{ location?: Location }>();
  const [authenticated, setAuthenticated] = useState<boolean>();

  useEffect(() => {
    let authorized = false;
    for (const allow of route.Allowed) {
      switch (allow) {
        case "all":
          authorized = true;
          break;
        case "guest":
          if (!userContext.loggedIn) authorized = true;
          break;
        case "user":
          if (userContext.loggedIn) authorized = true;
          break;
        case "client":
          if (userContext.loggedIn && userContext.user?.role === Role.Client)
            authorized = true;
          break;
        case "realtor":
          if (userContext.loggedIn && userContext.user?.role === Role.Realtor)
            authorized = true;
          break;
        case "admin":
          if (userContext.loggedIn && userContext.user?.role === Role.OrgAdmin)
            authorized = true;
          break;
        case "god":
          if (userContext.loggedIn && userContext.user?.role === Role.SysOp)
            authorized = true;
          break;
      }
    }

    if (authorized) routeContext.setCurrentRoute(route);
    else
      history.replace(route.RedirectUnauthorized ?? "/login", {
        location:
          (history.location.state?.location as Location) ?? history.location,
      });

    setAuthenticated(authorized);
    // eslint-disable-next-line
  }, [route]);

  return (
    <>
      <Helmet>
        <title>{route.Title}</title>
      </Helmet>
      {authenticated === true && <route.View />}
    </>
  );
}) as React.FC<{ route: IRoute }>;

export const RT = (route: IRoute) => {
  return (
    <Route path={route.Route} exact={route.Exact ?? true} key={route.Route}>
      <RTWrap route={route} />
    </Route>
  );
};

const Routes = (
  routes: IRoute[],
  parent: boolean = true
): React.ReactNode[] => {
  let list = routes
    .map((route, i) => {
      return [
        RT(route),
        route.Children != null ? Routes(route.Children, false) : [],
      ];
    })
    .flat(Infinity) as React.ReactNode[];
  return list;
};

export default Routes;
