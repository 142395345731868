import React from "react";
import {
  Card,
  CardDeck,
  Col,
  Container,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Check } from "react-feather";
import YouTube from "react-youtube";

const Videos = () => {
  return (
    <Container fluid>
      <style>{`
            .card {
                min-width: 18em;
                max-width: 18em;
            }
        `}</style>

      <Row>
        <Col>
          <h1>Videos</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <YouTube videoId="dQw4w9WgXcQ" className="youtube-video" />
        </Col>
      </Row>
      <CardDeck className="d-flex flex-row flex-nowrap w-100 overflow-auto m-0 pr-2 mt-4 px-2">
        <Card className="ml-0">
          <Card.Img variant="top" src="holder.js/100px160" />
          <Card.Body>
            <Card.Title>
              <Check /> Title
            </Card.Title>
            This is a video oh boy!
          </Card.Body>
          <Card.Footer>
            <ProgressBar now={100} />
            <span className="text-muted">100% watched</span>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="holder.js/100px160" />
          <Card.Body>
            <Card.Title>
              <Check /> Title
            </Card.Title>
            This is a video oh boy!
          </Card.Body>
          <Card.Footer>
            <ProgressBar now={100} />
            <span className="text-muted">100% watched</span>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="holder.js/100px160" />
          <Card.Body>
            <Card.Title>
              <Check /> Title
            </Card.Title>
            This is a video oh boy!
          </Card.Body>
          <Card.Footer>
            <ProgressBar now={100} />
            <span className="text-muted">100% watched</span>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="holder.js/100px160" />
          <Card.Body>
            <Card.Title>
              <Check /> Title
            </Card.Title>
            This is a video oh boy!
          </Card.Body>
          <Card.Footer>
            <ProgressBar now={100} />
            <span className="text-muted">100% watched</span>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="holder.js/100px160" />
          <Card.Body>
            <Card.Title>
              <Check /> Title
            </Card.Title>
            This is a video oh boy!
          </Card.Body>
          <Card.Footer>
            <ProgressBar now={100} />
            <span className="text-muted">100% watched</span>
          </Card.Footer>
        </Card>
      </CardDeck>
    </Container>
  );
};

export default Videos;
