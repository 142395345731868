import React from "react";
import Form from "./Form";
import { Col } from "react-bootstrap";

const HomeFeature = (({ name, label, removable }) => {
  return (
    <Form.Row>
      <Form.Field
        name={name}
        type="tabs"
        column="auto"
        placeholder="Feature"
        options={[
          {
            label: "Y",
            value: "Y",
            variant: "outline-success",
          },
          {
            label: "N",
            value: "N",
            variant: "outline-danger",
          },
          {
            label: "N/A",
            value: "NA",
            variant: "outline-dark",
          },
          ...(removable
            ? [
                {
                  label: "?",
                  value: "",
                  variant: "outline-secondary",
                },
              ]
            : []),
        ]}
      />
      <Col className="my-auto ml-2">{label}</Col>
    </Form.Row>
  );
}) as React.FC<{ name: string; label: string; removable?: true }>;

export default HomeFeature;
