import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import NewContactForm from "../../Components/Forms/NewContactForm";
import { RouteContext } from "../../Routing/Routing";
import {
  DeleteDirectories,
  UpdateDirectories,
  GetOrganization,
} from "../../Queries";
import { useQuery, useMutation } from "@apollo/client";
import {
  UpdateDirectories as UpdateDirectoriesQuery,
  UpdateDirectoriesVariables,
} from "../../types/UpdateDirectories";
import {
  DeleteDirectories as DeleteDirectoriesquery,
  DeleteDirectoriesVariables,
} from "../../types/DeleteDirectories";
import Loader from "../../Components/Loader";
import NewContactSchema from "../../Validators/NewContactForm";
import {
  GetOrganization as GetOrganizationQuery,
  GetOrganizationVariables,
} from "../../types/GetOrganization";

const ManageDirectory = () => {
  const {
    routeData: { oid },
    addToast,
  } = useContext(RouteContext);
  const history = useHistory();
  useEffect(() => {
    if (oid == null) {
      history.replace("/manage");
    }
  }, [oid, history]);

  let { cid } = useParams<{ cid: string }>();

  const { data, loading } = useQuery<
    GetOrganizationQuery,
    GetOrganizationVariables
  >(GetOrganization, {
    variables: { id: oid },
    partialRefetch: true,
    returnPartialData: false,
    fetchPolicy: "network-only",
  });

  let type_id =
    data?.organization?.directories?.find((category) => category.id === cid) ??
    null;

  //call to delete directory contact
  const [deleteDirectories] = useMutation<
    DeleteDirectoriesquery,
    DeleteDirectoriesVariables
  >(DeleteDirectories);

  //call to update directory contact
  const [updateDirectories] = useMutation<
    UpdateDirectoriesQuery,
    UpdateDirectoriesVariables
  >(UpdateDirectories);

  async function handleDelete() {
    const variables: DeleteDirectoriesVariables = {
      id: cid as string,
    };
    const { data } = await deleteDirectories({ variables });

    // if sucess then show message
    if (data) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Directory Contact successfully deleted!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  async function submitForm({
    first_name,
    last_name,
    phone_number,
    email,
    address_1,
    address_2,
    city,
    state,
    country,
    postal_code,
    category,
  }: any) {
    const variables = {
      input: {
        id: type_id?.id as string,
        first_name,
        last_name,
        phone_number,
        email,
        address_1,
        address_2,
        city,
        state,
        country,
        postal_code,
        category: { connect: category },
      },
    };

    const { data } = await updateDirectories({ variables });

    //if sucess then show message
    if (data?.updateDirectory) {
      addToast({
        header: <strong>Success!</strong>,
        body: "Directory Contact successfully updated!",
      });
      history.push(`/manage/organizations/${oid}`);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Manage Contact</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-right">
          <Button
            variant="danger"
            className="btn btn-primary"
            disabled={!cid}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Row>
      <Loader loaded={!loading && data != null}>
        <Formik
          onSubmit={submitForm}
          initialValues={{
            first_name: type_id?.first_name ?? "",
            last_name: type_id?.last_name ?? "",
            email: type_id?.email ?? "",
            phone_number: type_id?.phone_number ?? "",
            location: {
              address_1: type_id?.address_1 ?? "",
              address_2: type_id?.address_2 ?? "",
              city: type_id?.city ?? "",
              state: type_id?.state ?? "",
              postal_code: type_id?.postal_code ?? "",
              country: type_id?.country ?? null,
            },
            category: type_id?.category?.id ?? null,
          }}
          validationSchema={NewContactSchema}
        >
          <NewContactForm />
        </Formik>
      </Loader>
    </Container>
  );
};

export default ManageDirectory;
