import { useFormikContext } from "formik";
import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { UserContext } from "../../Sources/UserSource";
import Form from "../Form";
import HomeFeature from "../HomeFeature";
import Loader from "../Loader";
import LocationForm from "../LocationForm";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import { GetHome as GetHomeQuery, GetHomeVariables } from "../../types/GetHome";
import { GetHome } from "../../Queries";
import AutoComplete from "./AutoComplete";

const ManageHomeForm = (() => {
  const { dirty, isValid, isSubmitting } = useFormikContext();
  const { user } = useContext(UserContext);
  const { organization } = user ?? {};
  const { features, homeQualities, homeTypes } = organization ?? {};
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const history = useHistory();
  const { bid } = useParams<{ bid: string }>();
  const priceInterval = organization?.settings
    ?.slice(0, 1)
    .map((val) => val.value);
  function redirectToTarget() {
    history.push(`/homes/found/${bid}`);
  }

  const { data } = useQuery<GetHomeQuery, GetHomeVariables>(GetHome, {
    variables: {
      id: bid,
    },
  });

  function checkFoundHome() {
    if (data?.home?.found_home) {
      return true;
    } else {
      return false;
    }
  }

  const setViewFoundHome = () => {
    if (data?.home?.found_home) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Form className="mt-2">
      <Loader loaded={!isSubmitting} />
      <h2>Buyer information</h2>
      <Form.Row>
        <Form.Field
          name="first_name"
          type="text"
          placeholder="First name"
          column
        />
        <Form.Field
          name="last_name"
          type="text"
          placeholder="Last name"
          column
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          name="phone_number"
          type="text"
          placeholder="Phone Number"
          column
        />
        <Form.Field name="email" type="text" placeholder="Email" column />
      </Form.Row>
      <LocationForm name="buyer_location" />
      <Form.Row>
        <Form.Field
          name="needs_sale"
          type="switch"
          label="Must be sold"
          column
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          name="renting"
          type="switch"
          label="Renting Currently?"
          column
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          name="oncollaboption"
          type="switch"
          label="On Collab Center?"
          column
        />
      </Form.Row>
      <Row>
        <Col>
          <h2 className="mt-4">Criteria</h2>
        </Col>
      </Row>
      <Form.Row>
        <Col>
          <h6 className="text-muted">Location*</h6>
        </Col>
      </Form.Row>
      <AutoComplete name="auto_complete" />
      <Form.Row>
        <Form.Field
          name="min_price"
          type="number"
          step={priceInterval?.toString()}
          min="0"
          label="Minimum Price (optional)"
          column
        />
        <Form.Field
          name="max_price"
          type="number"
          step={priceInterval?.toString()}
          min="0"
          label="Maximum Price (required)"
          column
        />
      </Form.Row>
      <Form.Row>
        <Form.Field
          name="min_beds"
          type="number"
          min="0"
          label="Minimum Bedrooms"
          column
        />
        <Form.Field
          name="min_baths"
          type="number"
          min="0"
          label="Minimum Bathrooms"
          column
        />
        <Form.Select
          name="type"
          type="select"
          placeholder="Select..."
          label="House Type*"
          column
          options={homeTypes?.map((type) => ({
            value: type.id,
            label: type.name,
          }))}
          disabled={homeTypes == null}
          loading={homeTypes == null}
        />
      </Form.Row>
      <Form.Row>
        {isTabletOrMobile ? (
          <Loader loaded={homeQualities != null}>
            <Form.Field
              style={{ flexDirection: "column" }}
              name="quality"
              type="tabs"
              column
              options={
                homeQualities
                  ?.slice()
                  ?.sort((a, b) => a.positivity - b.positivity)
                  ?.map((quality) => ({
                    label: quality.name,
                    value: quality.id,
                  })) ?? []
              }
            />
          </Loader>
        ) : (
          <Loader loaded={homeQualities != null}>
            <Form.Field
              style={{ flexDirection: "row" }}
              name="quality"
              type="tabs"
              column
              options={
                homeQualities
                  ?.slice()
                  ?.sort((a, b) => a.positivity - b.positivity)
                  ?.map((quality) => ({
                    label: quality.name,
                    value: quality.id,
                  })) ?? []
              }
            />
          </Loader>
        )}
      </Form.Row>
      <Row className="mt-4">
        <Col>
          <h2>Features*</h2>
        </Col>
      </Row>
      <Loader loaded={features != null}>
        {features?.map((feature) => (
          <HomeFeature
            key={feature.id}
            name={`features.${feature.id}`}
            label={feature.name}
          />
        ))}
      </Loader>
      <Row className="mt-4">
        <Col>
          <h2>Additional Notes*</h2>
        </Col>
      </Row>
      <Form.Row>
        <Form.Field
          name="additional_notes"
          as="textarea"
          rows={10}
          style={{ resize: "none" }}
          column
          className="bg-secondary p-2"
        />
      </Form.Row>
      <Form.Row>
        <Button
          type="submit"
          variant="primary"
          className={`${
            !(dirty && isValid) ? "disabled-btn" : ""
          } mr-1 col-auto`}
          disabled={!(isValid && dirty) || isSubmitting}
        >
          Submit
        </Button>
        <Button
          name="btn_foundHome"
          variant="success"
          disabled={checkFoundHome()}
          className="mr-1 col-auto"
          onClick={redirectToTarget}
        >
          Found Home
        </Button>
        <Button
          name="btn_viewHome"
          variant="success"
          disabled={setViewFoundHome()}
          className="mr-1 col-auto"
          onClick={redirectToTarget}
        >
          View Found Home
        </Button>
      </Form.Row>
    </Form>
  );
}) as React.FC<{}>;

export default ManageHomeForm;
