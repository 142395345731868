import * as yup from "yup";

const LocationSchema = yup.object().shape({
  address_1: yup.string().required().label("Address line 1"),
  address_2: yup.string().notRequired().nullable().label("Address line 2"),
  city: yup.string().required().label("City"),
  state: yup.string().required().label("Province/state"),
  postal_code: yup
    .string()
    .required()
    .label("Postal/ZIP code")
    .matches(
      /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
      "Incorrect format, E.g. B1P 4A1"
    ),
  country: yup.string().required().label("Country"),
});

export default LocationSchema;
