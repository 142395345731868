import { useFormikContext } from "formik";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "../Form";
import Loader from "../Loader";

const AddLocationComp = (() => {
  const { isValid, dirty, isSubmitting } = useFormikContext<any>();
  const [disable, setDisable] = useState(false);

  return (
    <div>
      {disable ? (
        <div style={{ pointerEvents: "none", opacity: "0.4" }}>
          <Form className="mt-4">
            <Loader loaded={!isSubmitting} />
            <Form.Row>
              <Form.Field
                type="text"
                name="name"
                placeholder="Location Name"
                column
              />
            </Form.Row>
            <Row className="mt-4">
              <Col>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid || !dirty}
                      className="mr-1"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div>
          <Form className="mt-4">
            <Loader loaded={!isSubmitting} />
            <Form.Row>
              <Form.Field
                type="text"
                name="name"
                placeholder="Location Name"
                column
              />
            </Form.Row>
            <Row className="mt-4">
              <Col>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid || !dirty}
                      className="mr-1"
                      onClick={() => setDisable(true)}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}) as React.FC;

export default AddLocationComp;
