import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "../../Components/Form";
import { Formik } from "formik";
import * as yup from "yup";

const ForgotPassword = () => {
  const onSubmit = () => {};
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Recover account</h1>
          <p>
            To recover your account, enter your email below. You will be emailed
            a link to reset your account's password.
          </p>
        </Col>
      </Row>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          email: yup.string().email().label("Email"),
        })}
      >
        <Form>
          <Form.Field name="email" type="email" placeholder="Email" />
          <Button type="submit" variant="danger">
            Recover Account
          </Button>
        </Form>
      </Formik>
    </Container>
  );
};

export default ForgotPassword;
