import { useFormikContext } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "../Form";
import Loader from "../Loader";

const EditdLocationComp = (() => {
  const { isValid, dirty, isSubmitting } = useFormikContext<any>();
  return (
    <Form className="mt-4">
      <Loader loaded={!isSubmitting} />
      <Form.Row>
        <Form.Field
          type="text"
          name="name"
          placeholder="Location Name"
          column
        />
      </Form.Row>

      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <Button
                variant="primary"
                disabled={!isValid || !dirty}
                type="submit"
                className="mr-1"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}) as React.FC;

export default EditdLocationComp;
