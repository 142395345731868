import LocationSchema from "./Location";
import * as yup from "yup";
import "yup-phone";

const NewContactSchema = yup.object().shape({
  first_name: yup.string().required("Please enter First Name "),
  last_name: yup.string().required("Please enter First Name "),
  email: yup.string().required("Please enter email"),
  phone_number: yup.string().phone().label("Phone Number"),
  location: LocationSchema,
});

export default NewContactSchema;
