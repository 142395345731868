import React from "react";

const Loader = function ({ children, loaded = false }) {
  return loaded ? (
    children ?? null
  ) : (
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
} as React.FC<{ loaded?: boolean }>;

export default Loader;
