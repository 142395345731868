import React, { useContext } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { Search, LogOut, Phone, Plus, Users } from "react-feather";
import { Link } from "react-router-dom";
import { DISABLED_FEATURES } from "../../Sources/BuildMetadata";
import { UserContext } from "../../Sources/UserSource";
import { Role } from "../../types/globalTypes";

const HomeScreen = () => {
  const { user } = useContext(UserContext);

  let RoleType: boolean = false;
  const role = user?.role;

  if (role === Role.Realtor || role === Role.Client) {
    RoleType = true;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item
              action
              as={Link}
              to="/manage/homes/new"
              className="text-primary"
            >
              <Plus className="mr-2" /> Add Buyer
            </ListGroup.Item>
            <ListGroup.Item
              action
              as={Link}
              to="/homes"
              className="text-primary"
            >
              <Search className="mr-2" /> Search
            </ListGroup.Item>
            <ListGroup.Item
              action
              as={Link}
              to="/directory"
              className={
                DISABLED_FEATURES.includes("DIRECTORY")
                  ? "text-disabled"
                  : "text-primary"
              }
              disabled={DISABLED_FEATURES.includes("DIRECTORY")}
            >
              <Phone className="mr-2" /> Directory{" "}
              {DISABLED_FEATURES.includes("DIRECTORY") && "(NOT READY)"}
            </ListGroup.Item>
            {RoleType ? (
              ""
            ) : (
              <ListGroup.Item
                action
                as={Link}
                to={`/manage/organizations/${user?.organization?.id}`}
                className="text-primary"
              >
                <Users className="mr-2" /> Organization Settings
              </ListGroup.Item>
            )}
            <ListGroup.Item
              action
              as={Link}
              to="/logout"
              className="text-primary"
            >
              <LogOut className="mr-2" /> Log out
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeScreen;
